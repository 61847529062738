import React from 'react';
import styled from 'styled-components';

import Button from 'components/button';
import ErrorMessage from 'components/errormessage';
import AutoComplete from 'components/autocomplete';
import SelectedItems from 'components/selecteditems';
import SkillAutocompleteField from 'components/skillautocompletefield';
import SelectSeniorityLevel from 'components/selectquestion/SelectSeniorityLevel';

import ScoreInput from '../candidatequalityscoreform/QualityScoreField';
import { isValidQualityScore } from '../candidatequalityscoreform';

type State = $TSFixMe;

export default class CandidateSkillsForm extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      jobTitles: (props as $TSFixMe).jobTitles || [],
      primarySkills: (props as $TSFixMe).primarySkills || [],
      secondarySkills: [],
      seniorities: (props as $TSFixMe).seniorities || [],
      note: '',
      qualityScore: undefined,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.setState({
      jobTitles: (this.props as $TSFixMe).jobTitles || [],
      primarySkills: (this.props as $TSFixMe).primarySkills || [],
      seniorities: (this.props as $TSFixMe).seniorities || []
    });
  }

  handleScoreChange = (score: number | undefined) => {
    this.setState({ qualityScore: score });
  };

  onSubmit() {
    const { primarySkills, jobTitles, secondarySkills, seniorities, note, qualityScore } =
      this.state;
    // @ts-expect-error TS(2339) FIXME: Property 'onSubmit' does not exist on type 'Readon... Remove this comment to see the full error message
    const { onSubmit } = this.props;

    if (jobTitles.length === 0) {
      this.setState({ errorMessage: 'Please select at least one job title.' });
    } else if (primarySkills.length === 0) {
      this.setState({ errorMessage: 'Please select at least one core skill.' });
    } else if (seniorities.length === 0) {
      this.setState({ errorMessage: 'Please select at least one seniority.' });
    } else if (!isValidQualityScore(qualityScore)) {
      this.setState({ errorMessage: 'Quality score must be an integer between 1 and 10.' });
    } else onSubmit({ jobTitles, primarySkills, secondarySkills, seniorities, note, qualityScore });
  }

  selectJobTitle(option: $TSFixMe) {
    const jobTitles = [...this.state.jobTitles];

    jobTitles.push(option.value);

    this.setState({ jobTitles });
  }

  removeJobTitle(value: $TSFixMe) {
    const jobTitles = [...this.state.jobTitles];
    const index = jobTitles.indexOf(value);

    if (index > -1) jobTitles.splice(index, 1);

    this.setState({ jobTitles });
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'jobTitleOptions' does not exist on type ... Remove this comment to see the full error message
    const { jobTitleOptions, loading } = this.props;
    const {
      primarySkills,
      secondarySkills,
      jobTitles,
      seniorities,
      note,
      qualityScore,
      errorMessage
    } = this.state;

    return (
      <Form className="candidate_info_form">
        <Field>
          <AutoComplete
            list={jobTitleOptions}
            id="candidate_skills_select_job_title"
            onSelect={(jobTitle: $TSFixMe) => this.selectJobTitle(jobTitle)}
            selectedItems={jobTitles}
            searchThroughTags
            removeSelected
            placeholder="Select job title(s)"
            disabled={jobTitles.length > 2}
            disabledPlaceholder="All 3 job titles selected. Remove one to add another one."
          />
          <SelectedItems
            selectedItems={jobTitles}
            columns={3}
            removeItem={(value: $TSFixMe) => this.removeJobTitle(value)}
          />
        </Field>
        <SelectSeniorityLevel
          question="Seniority level?"
          selected={seniorities}
          setSeniorities={seniorities => this.setState({ seniorities })}
        />
        <SkillAutocompleteField
          defaultSkills={primarySkills}
          otherSkills={secondarySkills}
          onSkillUpdate={(skills: $TSFixMe) => this.setState({ primarySkills: skills })}
          showQuestion={false}
          maxSelection={3}
          skillType="core skills"
          showRecommendationsFor="primary"
          columns={3}
          disableItemsLabel="Other"
          selectedJobTitles={jobTitles}
        />
        <SkillAutocompleteField
          defaultSkills={secondarySkills}
          otherSkills={primarySkills}
          onSkillUpdate={(skills: $TSFixMe) => this.setState({ secondarySkills: skills })}
          showQuestion={false}
          maxSelection={5}
          skillType="other skills"
          showRecommendationsFor="secondary"
          columns={5}
          disableItemsLabel="Core"
          optional
        />
        <Field>
          <input
            type="text"
            placeholder="Enter other notes"
            className="notes_input"
            value={note}
            onChange={e => this.setState({ note: e.target.value })}
          />
        </Field>
        <Field>
          <ScoreInput value={qualityScore} onChange={this.handleScoreChange} />
        </Field>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Button
          text="Save profile info"
          className="save_info_button"
          action={() => this.onSubmit()}
          loading={loading}
        />
      </Form>
    );
  }
}

const Form = styled.div`
  position: relative;
  text-align: right;
`;

const Field = styled.div`
  display: block;
  text-align: left;
  margin-bottom: 10px;

  .autocomplete_cont .search_input,
  input {
    margin: 0;
  }
`;
