import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import { mediaQueries } from 'responsiveConfig';
import { useNavigatablePositionsStore } from 'store/navigatablePositions';
import useEventListener from 'hooks/useEventListener';
import useMedia from 'hooks/useMedia';
import { lastIndex } from 'utils/array';
import { generatePositionURL } from 'utils';

import { PositionProfileViewProps } from 'views/positionprofile';

type NavigatablePositionsContextObject = {
  positionProfileViewProps: PositionProfileViewProps;
  allowNavigatePreviousButton: boolean;
  allowNavigateNextButton: boolean;
  leftNavigatablePositionID: number;
  middleNavigatablePositionID: number;
  rightNavigatablePositionID: number;
  isMobile: boolean;
  navigateToPrevious: () => void;
  navigateToNext: () => void;
};

const NavigatablePositionsContext = createContext<NavigatablePositionsContextObject | null>(null);

export default function NavigatablePositionsProvider({
  children,
  ...props
}: PropsWithChildren<PositionProfileViewProps>) {
  const { navigatablePositionsIDs, cachedNavigatablePositions } = useNavigatablePositionsStore();
  const initialIndex = navigatablePositionsIDs.findIndex(
    value => value === Number(props.match.params.id)
  );
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const history = useHistory();
  const location = useLocation();
  const isMobile = useMedia([mediaQueries.mobile]);

  const allowNavigatePreviousButton = currentIndex > 0;
  const allowNavigateNextButton = currentIndex < lastIndex(navigatablePositionsIDs);

  const updateURL = (position: any) => {
    if (!position) return;
    const positionURL = generatePositionURL(location, {
      companyName: position.companyName,
      positionID: position.id,
      positionName: position.position
    });
    if (positionURL) history.push({ pathname: positionURL, search: location.search });
  };

  const navigate = (direction: 'previous' | 'next') => {
    const isPrevious = direction === 'previous';
    const isNext = direction === 'next';

    if ((isPrevious && !allowNavigatePreviousButton) || (isNext && !allowNavigateNextButton)) {
      return;
    }

    setCurrentIndex(currentIndex => {
      const newIndex = isPrevious ? currentIndex - 1 : currentIndex + 1;
      const newPosition = cachedNavigatablePositions[navigatablePositionsIDs[newIndex]];
      updateURL(newPosition);
      return newIndex;
    });
  };

  const navigateToPrevious = () => navigate('previous');
  const navigateToNext = () => navigate('next');

  const leftNavigatablePositionID = navigatablePositionsIDs[currentIndex - 1];
  const middleNavigatablePositionID = navigatablePositionsIDs[currentIndex];
  const rightNavigatablePositionID = navigatablePositionsIDs[currentIndex + 1];

  const handleKeydown = (event: Event | KeyboardEvent) => {
    if ((event.target as HTMLElement).getAttribute('id') === 'message_template') return;

    switch ((event as KeyboardEvent).key) {
      case 'ArrowRight':
        navigateToNext();
        break;
      case 'ArrowLeft':
        navigateToPrevious();
        break;
      default:
        break;
    }
  };

  useEventListener('keydown', handleKeydown);

  const value = {
    positionProfileViewProps: props,
    allowNavigatePreviousButton,
    allowNavigateNextButton,
    leftNavigatablePositionID,
    middleNavigatablePositionID,
    rightNavigatablePositionID,
    isMobile,
    navigateToPrevious,
    navigateToNext
  };

  return (
    <NavigatablePositionsContext.Provider value={value}>
      {children}
    </NavigatablePositionsContext.Provider>
  );
}

export const useNavigatablePositionsContext = () => {
  const navigatablePositionsContext =
    useContext(NavigatablePositionsContext) || ({} as NavigatablePositionsContextObject);

  return navigatablePositionsContext;
};
