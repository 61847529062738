import React, { createContext, useContext, PropsWithChildren, useState, useEffect } from 'react';

import { CandidateInsightsResponse } from '@cohiretech/common-types';

import { useCandidatePro } from 'store';
import { useAxiosFetch } from 'hooks/useAxiosFetch';
import { useOnUnmount } from 'hooks/useOnUnmount';
import { useSearchParams } from 'hooks/useSearchParams';

import { getPositionInsightsData } from './PositionInsights.helpers';
import {
  getFromLocalStorage,
  PositionProfileProps,
  saveToLocalStorage
} from './PositionProfile.helpers';
import dummyData from './insights/dummyData.json';

export type PositionProfileContextState = {
  insightsData: CandidateInsightsResponse;
  isDummyData: boolean;
  showInsights: boolean;
  loadingInfo: InsightsLoadedInfo;
  isCandidateCreatedATPosition: boolean;
  setLoadingDetails: (info: Partial<InsightsLoadedInfo>) => void;
} & PositionProfileProps;

const PositionProfileContext = createContext<PositionProfileContextState | undefined>(undefined);

type PositionProfileProviderProps = PropsWithChildren<PositionProfileProps>;

type InsightsLoadedInfo = {
  hasLoadedOnce: boolean;
  isLoading: boolean;
  canFetchData?: boolean;
};

const PositionProfileProvider = (props: PositionProfileProviderProps) => {
  const { children, ...rest } = props;
  const [loadingInfo, setLoadingInfo] = useState<InsightsLoadedInfo>({
    hasLoadedOnce: false,
    isLoading: true
  });

  useEffect(() => {
    if (!rest.id) return;
    setLoadingInfo({
      hasLoadedOnce: getFromLocalStorage(rest.position, rest.id),
      isLoading: isEnabledForAccount
    });
  }, [rest.id]);

  const setLoadingDetails = (info: Partial<InsightsLoadedInfo>) => {
    if (info.hasLoadedOnce) saveToLocalStorage(rest.position, rest.id);
    setLoadingInfo(prev => ({ ...prev, ...info } as InsightsLoadedInfo));
  };

  const { isCandidateProEnabled: isEnabledForAccount, isCandidateProAllowed } = useCandidatePro();

  const isDummyData = isCandidateProAllowed && !isEnabledForAccount;

  const { updateSearchParams } = useSearchParams();
  useOnUnmount(() => updateSearchParams({ tab: '' }));

  const options = {
    args: [props.id],
    predicate: !!props.id && isEnabledForAccount && loadingInfo.canFetchData,
    dependencies: [props.id, isEnabledForAccount, loadingInfo.canFetchData]
  };
  const { data } = useAxiosFetch<CandidateInsightsResponse>(getPositionInsightsData, options);
  const initialData = isEnabledForAccount ? {} : dummyData;
  const insightsData = data || initialData;

  const isCandidateCreatedATPosition = !!rest.candidateApplicationTrackerCreatedListingID;
  const showInsights =
    isCandidateProAllowed && !rest.loading && !rest.external && !isCandidateCreatedATPosition;

  /** Todo:
   * - Less of the state from props
   * - Distribute to children via usePositionProfile
   */
  const value = {
    ...rest,
    insightsData,
    isDummyData,
    showInsights,
    loadingInfo,
    isCandidateCreatedATPosition,
    setLoadingDetails
  };

  return (
    <PositionProfileContext.Provider value={value}>{children}</PositionProfileContext.Provider>
  );
};

const usePositionProfile = () => {
  const context = useContext(PositionProfileContext);
  if (!context) {
    throw new Error('usePositionProfile must be used within a PositionProfileProvider');
  }
  return context;
};

export { PositionProfileProvider, usePositionProfile };
