import React from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { SizeVariant } from 'types';
import { mediaQueries } from 'responsiveConfig';
import { isObject_Deprecated } from 'utils';

import colours from 'css/base/_colours.module.scss';

export type SelectedItemsProps = {
  selectedItems: any[];
  columns?: number;
  ordered?: boolean;
  light?: boolean;
  size?: Extract<SizeVariant, 'xsmall'>;
  rounded?: boolean;
  removeItem: (item: any) => void;
  onOrderUpdate?: (item: any[]) => void;
};

type State = {
  draggedIndex: number;
  draggingOverIndex: number;
};

type StyledItem = Pick<SelectedItemsProps, 'columns' | 'light' | 'size' | 'rounded'> & {
  draggable: 'true' | 'false';
};

export default class SelectedItems extends React.Component<SelectedItemsProps, State> {
  constructor(props: SelectedItemsProps) {
    super(props);

    this.state = {
      draggedIndex: -1,
      draggingOverIndex: -1
    };
  }

  trackDragOverIndex(e: $TSFixMe, index: $TSFixMe) {
    e.preventDefault();

    this.setState({ draggingOverIndex: index });
  }

  allowDrop(e: $TSFixMe) {
    e.preventDefault();
  }

  onDrop(e: $TSFixMe, newIndex: $TSFixMe) {
    const { draggedIndex } = this.state;
    const { onOrderUpdate } = this.props;

    e.preventDefault();

    if (newIndex > -1 && draggedIndex > -1) {
      const selectedItems = [...(this.props as $TSFixMe).selectedItems];
      const item = selectedItems[draggedIndex];

      selectedItems.splice(draggedIndex, 1);
      selectedItems.splice(newIndex, 0, item);

      onOrderUpdate?.(selectedItems);
      this.setState({ draggedIndex: -1 });
    }
  }

  onDrag(index: $TSFixMe) {
    this.setState({ draggedIndex: index });
  }

  render() {
    const {
      selectedItems,
      removeItem,
      columns = 1,
      ordered = false,
      light = false,
      size,
      rounded
    } = this.props;
    const { draggingOverIndex, draggedIndex } = this.state;

    return (
      <Items
        className={ClassNames('selected_items', { empty: !selectedItems.length })}
        size={size}
        onDragOver={e => this.allowDrop(e)}
        onDrop={e => this.onDrop(e, draggingOverIndex)}
      >
        {selectedItems.map((option: $TSFixMe, index: $TSFixMe) => {
          const isOptionAnObject = isObject_Deprecated(option);
          return (
            <Item
              key={`${isOptionAnObject ? option?.label : option}_${index}`}
              columns={columns}
              light={light}
              title={isOptionAnObject ? option?.label : option}
              size={size}
              onDragOver={
                ordered
                  ? e => this.trackDragOverIndex(e, index)
                  : () => {
                      return;
                    }
              }
              onDragStart={
                ordered
                  ? () => this.onDrag(index)
                  : () => {
                      return;
                    }
              }
              draggable={ordered ? 'true' : 'false'}
              className={ClassNames({
                drag_over:
                  draggedIndex > -1 &&
                  draggingOverIndex === index &&
                  draggingOverIndex !== draggedIndex,
                margin_left: draggedIndex > draggingOverIndex,
                margin_right: draggedIndex < draggingOverIndex
              })}
              rounded={rounded}
            >
              <Label className="selected_item_label">
                {ordered ? `${index + 1}. ` : ''}
                {isOptionAnObject ? option?.label : option}
              </Label>
              <div className="actions">
                <span
                  className="icon_close"
                  onClick={() => removeItem(isOptionAnObject ? option?.label : option)}
                />
              </div>
            </Item>
          );
        })}
      </Items>
    );
  }
}

const Items = styled.div<Pick<SelectedItemsProps, 'size'>>`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 10px 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ${({ size }) =>
    size === 'xsmall' &&
    css`
      margin: 0;
      padding: 0.25rem;
      padding-left: 6px;
    `}
`;

const Item = styled.div<StyledItem>`
  display: flex;
  gap: 0.25rem;
  margin: ${({ columns }) => columns === 1 && '5px 0'};
  box-sizing: border-box;
  padding: 12px;
  font-weight: ${({ theme: { typography } }) => typography.semibold};
  align-items: center;
  border-radius: ${({ rounded }) => (rounded ? '1.5rem' : '0.5rem')};
  cursor: ${({ draggable }) => (draggable === 'true' ? 'grab' : 'auto')};
  position: relative;
  transition: all 0.3s ease-in-out;

  ${({ rounded }) =>
    rounded
      ? css`
          background-color: var(--background-accent-secondary-soft);
        `
      : css`
          background-color: var(--background-option-primary-selected);
          color: var(--text-option-primary-selected);
        `}

  .actions .icon_close {
    padding: 0.25rem;
  }

  ${({ size, rounded, theme: { typography } }) =>
    size === 'xsmall' &&
    css`
      margin: 0;
      padding: 0.25rem;
      padding-left: ${rounded ? '0.75rem' : '0.5rem'};
      font-size: ${typography.small};

      .actions .icon_close {
        padding: 6px;
        font-size: ${typography.mobile};
      }
    `}

  &:nth-child(${({ columns }) => columns}n + 1) {
    margin-left: 0;
  }

  &.drag_over.margin_left {
    margin-left: 90px;

    &:before {
      position: absolute;
      content: '';
      width: 60px;
      height: 100%;
      left: -75px;
      top: 0;
      box-sizing: border-box;
      display: inline-block;
      border: 2px dashed ${colours.primaryColour};
      border-radius: 5px;
    }

    @media ${mediaQueries.mobile} {
      margin-left: 0;
      margin-top: 60px;

      &:before {
        width: 100%;
        height: 40px;
        left: 0;
        top: -50px;
      }
    }
  }

  &.drag_over.margin_right {
    margin-right: 90px;

    &:after {
      position: absolute;
      content: '';
      width: 60px;
      height: 100%;
      right: -80px;
      top: 0;
      box-sizing: border-box;
      display: inline-block;
      border: 2px dashed ${colours.primaryColour};
      border-radius: 5px;
    }

    @media ${mediaQueries.mobile} {
      margin-left: 0;
      margin-bottom: 60px;

      &:after {
        width: 100%;
        height: 40px;
        right: 0;
        top: inherit;
        bottom: -50px;
      }
    }
  }

  @media ${mediaQueries.mobile} {
    margin-bottom: 5px;

    ${({ columns }) =>
      columns === 3 || columns === 5
        ? css`
            width: 100%;
            margin-left: 0;
          `
        : css`
            width: calc((100% - 12px) / 2);
            margin-left: 12px;
          `}
  }
`;

const Label = styled.span`
  flex: 1;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
