import React from 'react';

import {
  Frequency,
  OperatingCountry,
  PricingCountryDetails,
  PricingMode,
  PricingSubscriptionPlan
} from '@cohiretech/common-types';

import {
  CurrentPlanDetails,
  PricingCountry,
  PricingVer,
  StandardPlan,
  V5PricingOptions
} from 'types';
import { PaymentData } from 'v2/services/company/subscription';
import { CHECKOUT_URL_PATHS, STANDARD_PLAN_LIST, STANDARD_PLAN_PRICE_LIST } from 'consts';

import { TabType } from 'components/tabs';

const DISCOUNT_RATE_ON_ANNUAL_PLAN = 0.25;

export type PricingPlansSectionProps = {
  pricingVer: PricingVer;
  pricingCountry: PricingCountry;
  currentPlanDetails?: CurrentPlanDetails;
  availablePlans?: PricingSubscriptionPlan[];
  isPayPerHire?: boolean;
  hidePrice?: boolean;
};

export type StandardTier = {
  urlPath: string;
  mode?: PricingMode;
  enquired?: boolean;
  discountLabel?: string;
  allowFree?: boolean;
} & Partial<PricingCountryDetails>;

export type StandardTiers<V extends PricingVer = PricingVer> = {
  [key in Frequency]: { [key in StandardPlan<V>]: StandardTier };
};

type GetStandardTiersParams = PricingPlansSectionProps & {
  pricingCountry: PricingCountry;
};

export type PaymentPopup = { show: boolean } & PaymentData;

export const FREQUENCY_TABS: TabType<Frequency>[] = [
  { title: <span className="frequency_label">Monthly</span>, value: Frequency.monthly },
  {
    title: <span className="frequency_label">Annual</span>,
    value: Frequency.annual,
    badge: { text: '-25%', type: 'info_light' }
  }
];

const getDiscountLabel = (plan: StandardPlan, pricingCountry: PricingCountry) => {
  if (pricingCountry === OperatingCountry.US) return;

  switch (plan) {
    case V5PricingOptions.V5Standard1:
      return '-40% EARLY-STAGE DISCOUNT';
    case V5PricingOptions.V5Standard2:
      return '-20% START-UP DISCOUNT';
    default:
      break;
  }
};

const getPriceByFrequency = (price?: number, frequency?: Frequency) =>
  frequency === Frequency.monthly ? price : (price || 0) * (1 - DISCOUNT_RATE_ON_ANNUAL_PLAN);

const getDefaultStandardTiers = ({
  pricingVer,
  pricingCountry,
  currentPlanDetails
}: GetStandardTiersParams) => {
  const standardPlans = STANDARD_PLAN_LIST[pricingVer];
  const priceList = STANDARD_PLAN_PRICE_LIST[pricingVer];

  const createTiers = (frequency: Frequency) => {
    const tiers = {} as StandardTiers[Frequency];

    standardPlans.forEach(plan => {
      const {
        price = 0,
        discountedPrice,
        allowFree = false
      } = priceList[pricingCountry]?.[plan] || {};

      tiers[plan] = {
        price: getPriceByFrequency(price, frequency),
        hasDiscount: currentPlanDetails?.hasDiscount,
        discountedPrice: getPriceByFrequency(discountedPrice, frequency),
        discountLabel: getDiscountLabel(plan, pricingCountry),
        urlPath: CHECKOUT_URL_PATHS[plan],
        allowFree
      };
    });

    return tiers;
  };

  const allTiers = {} as StandardTiers;

  Object.values(Frequency).forEach(frequency => {
    allTiers[frequency] = createTiers(frequency);
  });

  return allTiers;
};

export const getUpdatedStandardTiers = (params: GetStandardTiersParams) => {
  const { pricingCountry, availablePlans } = params;

  const tiers = getDefaultStandardTiers(params);

  availablePlans?.forEach(({ pricing, subscriptionPlan: { plan } }) => {
    if (!plan) return;

    const { urlPath, mode, billingFrequency } = pricing;
    const { price, hasDiscount, discountedPrice } = pricing[pricingCountry] || {};
    const [tier] =
      Object.entries(CHECKOUT_URL_PATHS).find(([, value]) => value && urlPath.startsWith(value)) ||
      [];

    tiers[billingFrequency][tier as StandardPlan] = {
      price,
      hasDiscount,
      discountedPrice,
      urlPath,
      mode
    };
  });

  return tiers;
};
