import { useState } from 'react';

import { scrollToElementInList } from 'utils';

type Props = {
  listLength: number;
  listID: string;
};

export function useArrowKeysNavigation({ listLength, listID }: Props) {
  const [focusedIdx, setFocusedIdx] = useState(-1);

  const handleArrowKeys = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' && focusedIdx > 0) {
      scrollToElementInList(listID, `${listID}_item_${focusedIdx - 1}`, 'top');

      setFocusedIdx(focusedIdx - 1);
    } else if (e.key === 'ArrowDown' && focusedIdx < listLength - 1) {
      scrollToElementInList(listID, `${listID}_item_${focusedIdx + 1}`, 'bottom');

      setFocusedIdx(focusedIdx + 1);
    }
  };

  const resetFocusedIdx = () => {
    setFocusedIdx(-1);
  };

  return {
    focusedIdx,
    handleArrowKeys,
    resetFocusedIdx
  };
}
