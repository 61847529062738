import styled from 'styled-components';
import ClassNames from 'classnames';

import ROIVideo from 'components/roivideo';
import PageSection from 'components/pagesection';

import Spacing from 'v2/components/ui/styles/Spacing';

export default function ROISection() {
  return (
    <ThreePointROISection
      className={ClassNames('center_alignment', 'gradient_blue_background')}
      widthClassName="min_view"
      title="Get instant ROI with cord"
    >
      <Spacing height="10" />
      <ThreePointsWrapper>
        <ROIItem icon="icon_arrow_trending_down" title="£9,800" subTitle="avg. saving per hire" />
        <ROIItem icon="icon_clock" title="2 days" subTitle="fastest time to hire" />
        <ROIItem icon="icon_hired" title="3,000+" subTitle="direct hires made" />
      </ThreePointsWrapper>
      <Spacing height="10" />
      <ROIVideo applyGradientColour />
    </ThreePointROISection>
  );
}

const ThreePointROISection = styled(PageSection)`
  &&& {
    .button.fill_cord_gradient.with_icon {
      color: var(--text-body-white) !important;
    }

    .page_section_title {
      font-size: 2.25rem;
      margin-bottom: 0;

      ${({ theme: { media } }) => media.mobile`
        font-size: 1.5rem;
      `}
    }
  }
`;

const ROIItem = ({ icon, title, subTitle }: { icon: string; title: string; subTitle: string }) => (
  <ROIItemWrapper>
    <span className={ClassNames('roi_icon', icon, 'blue_gradient_icon')} />
    <strong className="blue_gradient_text">{title}</strong>
    <span>{subTitle}</span>
  </ROIItemWrapper>
);

const ROIItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;

  strong {
    font-size: 2rem;
  }

  span {
    font-size: 1.25rem;
  }

  .roi_icon {
    font-size: 2rem;
    height: 2rem;
  }

  ${({ theme: { media } }) => media.mobile`
    strong {
      font-size: 1.375rem;
    }

    span {
      font-size: 1.125rem;
    }
  `}
`;

const ThreePointsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  ${({ theme: { media } }) => media.mobile`
    flex-direction: column;
    gap: var(--spacing-05);
  `}
`;
