import { PropertyChain, UserProfileType } from 'types';

import { DEV_FEATURES_INITIAL_STATE } from 'v2/components/utility/DevTools';

import { CandidateTour } from './ui';

const TOGGLER_ROUTES: PropertyChain[] = [
  'insights.trialExpiringBar',
  'loaders.fetchCompanyData',
  'loaders.fetchCandidateData',
  'candidate.homepageSetupPopup',
  'candidate.accountMenuDropdown',
  'positionSearchFilters',
  'candidate.search.remoteFilter' // Temporary
];

export type TogglerRoute = typeof TOGGLER_ROUTES[number];

export const CANDIDATE_TOUR: CandidateTour = [
  { name: 'discover', page: '/candidate/discover', shown: false },
  { name: 'search', page: '/candidate/search', shown: false },
  // CORD-4597 Hide them temporarily
  // {
  //   name: 'savedsearch',
  //   page: { hash: '#savedsearch', pathname: '/candidate/search/new' },
  //   shown: false
  // },
  // { name: 'map', page: { hash: '#map', pathname: '/candidate/search/new' }, shown: false },
  // CORD-5432 Hide messages by default
  // { name: 'messages', page: '/candidate/messages', shown: false },
  { name: 'tracker', page: '/candidate/tracker', shown: false },
  { name: 'insights', page: '/candidate/insights', shown: false },
  { name: 'profile', page: '/candidate/account', shown: false },
  // CORD-5432 Hide messages by default
  // { name: 'templates', page: '/candidate/templates', shown: false },
  { name: 'settings', page: '/candidate/settings', shown: false }
];

export const LOADER_TREE = {
  '/': { main: true },
  company: {
    messages: {
      main: false
    }
  }
};

export type ComponentLoaders = typeof LOADER_TREE;

export const TOGGLER_TREE = {
  dev: DEV_FEATURES_INITIAL_STATE
};

export type ComponentTogglers = typeof TOGGLER_TREE;

export const MOCK_MESSAGE_ID = -999; // FOR NEW MESSAGES

export type CSSVariables = {
  [type in Exclude<UserProfileType, null>]?: { [key: string]: string };
};
