import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { Frequency } from '@cohiretech/common-types';

import { PricingCountry, SubscriptionPlan } from 'types';
import { BOOK_A_CALL_LINK, GENERIC_BOOK_DEMO_LINK, REFERRALS_BOOK_DEMO_LINK } from 'consts';
import { getDemoLink } from 'utils/companyUtils';
import useLocalisation from 'hooks/useLocalisation';
import { useSearchParams } from 'hooks/useSearchParams';
import { selectCompanyUser, useAppSelector } from 'store';

export type HubSpotCalendarProps = {
  link?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: number;
  referrerID?: number;
  referredByCompany?: boolean;
};

type LocationState = { from: string; period?: Frequency; pricingType?: PricingCountry };

export const useCalendarURL = ({
  link,
  firstName,
  lastName,
  email,
  phoneNumber,
  referrerID,
  referredByCompany
}: HubSpotCalendarProps) => {
  const { memberName, accountEmail } = useAppSelector(selectCompanyUser) || {};

  const location = useLocation<LocationState | undefined>();
  const pricingCountry = useLocalisation(location.state?.pricingType) as PricingCountry;
  const { searchParams } = useSearchParams();

  const getCalendarLink = () => {
    if (link) return link;
    if (referredByCompany) return REFERRALS_BOOK_DEMO_LINK;
    if (location.pathname.includes('book-a-call')) return BOOK_A_CALL_LINK;

    const plan = searchParams.plan as SubscriptionPlan;
    const { from = '' } = location.state || {};

    if (plan || from) return getDemoLink(plan, from);
    return GENERIC_BOOK_DEMO_LINK;
  };

  const addSearchParams = (calendarLink: string) => {
    let url = `${calendarLink}?embed=true`;

    const firstNameParam = firstName || memberName;
    const emailParam = email || accountEmail;

    if (firstNameParam) url += `&firstname=${firstNameParam}`;
    if (lastName) url += `&lastname=${lastName}`;
    if (emailParam) url += `&email=${emailParam}`;
    if (phoneNumber) url += `&phone=${phoneNumber}`;
    if (referrerID) url += `&referrerid=${referrerID}`;

    return url;
  };

  const calendarURL = useMemo(() => {
    const calendarLink = getCalendarLink();
    return addSearchParams(calendarLink);
  }, [location, pricingCountry]);

  return calendarURL;
};
