import React from 'react';
import { Link } from 'react-router-dom';

import { CandidateProfile, PositionInProfile } from 'types';
import { candidateSearchFilters } from 'fetcher';
import { isEmpty } from 'utils';
import { getWorkEligibilityMatch } from 'v2/services/common';

export const getVisaBlockTooltipText = (profile: CandidateProfile, listing: PositionInProfile) => {
  const { workEligibilities: candidateWorkEligibilities } = profile;
  const { workEligibilities = [], visaSponsorshipAvailable, external } = listing;

  const visaMatch = getWorkEligibilityMatch(
    candidateWorkEligibilities,
    workEligibilities,
    visaSponsorshipAvailable
  );

  if (!visaMatch && !external) {
    const workEligibilityFilters = {
      attribute: candidateSearchFilters.workEligibility.attribute,
      value: true,
      label: `${candidateSearchFilters.workEligibility.label}`
    };
    const hasTemporaryVisa =
      candidateWorkEligibilities.findIndex(({ type }) => type === 'temporary') > -1;

    return (
      <>
        This position doesn't sponsor visas. The company has blocked requests from people that{' '}
        {hasTemporaryVisa ? 'have temporary work visa or' : ''} require visa sponsorship.{' '}
        <Link
          className="link"
          to={{
            pathname: '/candidate/search/new',
            state: !isEmpty(candidateWorkEligibilities) ? workEligibilityFilters : undefined
          }}
        >
          Find positions that sponsor
        </Link>
      </>
    );
  }
};

const CORDLIVE_JANUARY_24_LISTINGS = process.env.REACT_APP_CORDLIVE_LISTING_IDS!;

export const isListingInvisible = (listingID: number, listingInvisible: boolean) => {
  if (CORDLIVE_JANUARY_24_LISTINGS.includes(String(listingID))) return false; // always show listing
  return listingInvisible;
};

export const getPositionViewElement = () => {
  return document.getElementById('position_view')!;
};
