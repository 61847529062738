import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Banner from 'components/banner';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import Spacing, { SpacingProps } from 'v2/components/ui/styles/Spacing';
import { GradientText } from 'v2/components/ui/styles';

export default function SanFranciscoPricingBanner({
  show,
  spacing
}: {
  show: boolean;
  spacing?: SpacingProps['height'];
}) {
  return (
    <ConditionalRender predicate={show}>
      <Banner text={<ComingToSanFrancisco />} icon="icon_rocket" fitContent />
      <Spacing predicate={!!spacing} height={spacing} />
    </ConditionalRender>
  );
}

const ComingToSanFrancisco = () => {
  return (
    <SanFranWrapper>
      <span>We're launching in San Francisco Bay.</span>
      <Link to="/san-francisco?utm_source=sf_launch_banner">Get early access for free</Link>
    </SanFranWrapper>
  );
};

const SanFranWrapper = styled.span`
  ${GradientText};
  display: flex;
  gap: 0.5rem;

  min-width: 480px;

  ${({ theme: { media } }) => media.mobile`
    min-width: unset;
    display: inline;

    a {
      margin-left: 0.5rem;
    }
  `}

  span {
    font-weight: ${({ theme: { typography } }) => typography.semibold};
  }

  a {
    font-weight: ${({ theme: { typography } }) => typography.regular};
    border-bottom: 1px solid var(--color-primary-40);
  }
`;
