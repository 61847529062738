export const isBoolean = (val: unknown): val is boolean => typeof val === 'boolean';

export const isNumber = (val: unknown): val is number => typeof val === 'number';

export const isString = (val: unknown): val is string => typeof val === 'string';

export const isObject = (val: unknown): val is Record<string, unknown> =>
  typeof val === 'object' && val !== null && !Array.isArray(val);

export const isFunction = (val: unknown): val is Function => typeof val === 'function';

export const isUndefined = (val: unknown): val is undefined => typeof val === 'undefined';

export const isNull = (val: unknown): val is null => val === null;

export const isNil = (val: unknown): val is null | undefined => isUndefined(val) || isNull(val);

export const isDefined = <T>(val: T | undefined | null): val is T => !isNil(val);

export const isSymbol = (val: unknown): val is symbol => typeof val === 'symbol';

export const isDate = (val: unknown): val is Date => val instanceof Date;

export const isArray = <T = unknown>(val: unknown): val is T[] => Array.isArray(val);

export const isDateLike = (val: unknown): val is Date | string => {
  if (isDate(val)) return true;
  if (isString(val)) return !Number.isNaN(Date.parse(val));
  return false;
};
