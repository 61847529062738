import { OperatingCountry } from '@cohiretech/common-types';

import { PayPerHirePricingOptions, V5PricingOptions } from 'types';

import { BlockProps } from 'components/block';

import {
  PricingOption,
  PricingPlanCardType
} from 'v2/components/ui/organisms/PricingPlansSection/components/PricingPlanCard';

export const V5_PRICING_OPTIONS = Object.values(V5PricingOptions);

export const V5_STANDARD_PLANS = [
  V5PricingOptions.V5Standard1,
  V5PricingOptions.V5Standard2,
  V5PricingOptions.V5Standard3,
  V5PricingOptions.V5Standard4
] as const;

export const V5_PRICE_LIST = {
  [OperatingCountry.UK]: {
    [V5PricingOptions.V5Standard1]: { price: 989, discountedPrice: 589 },
    [V5PricingOptions.V5Standard2]: { price: 989, discountedPrice: 789 },
    [V5PricingOptions.V5Standard3]: { price: 989 },
    [V5PricingOptions.V5Standard4]: { price: 0 }
  },
  [OperatingCountry.US]: {
    [V5PricingOptions.V5Standard1]: { price: 589, discountedPrice: 0, allowFree: true },
    [V5PricingOptions.V5Standard2]: { price: 789, discountedPrice: 0, allowFree: true },
    [V5PricingOptions.V5Standard3]: { price: 789, discountedPrice: 0, allowFree: true },
    [V5PricingOptions.V5Standard4]: { price: 0 }
  }
};

export const PAY_PER_HIRE_OPTIONS = {
  plan: 'payperhire',
  defaultPlan: PayPerHirePricingOptions.PayPerHire,
  label: 'Pay per hire',
  description: 'A risk-free option to start making hires. Pay only when you hire.',
  featureTitle: 'Includes: ',
  features: [
    'Unlimited users, messages',
    'Access to every feature & integration',
    'Dedicated account management'
  ],
  button: {
    text: 'Book a call',
    buttonStyle: 'fill_cord_gradient',
    link: '/request-demo?plan=pay_per_hire'
  }
};

export const PRICING_V5_OPTIONS: {
  [key in OperatingCountry]: { [key in PricingPlanCardType]: PricingOption };
} = {
  [OperatingCountry.UK]: {
    standard: {
      plan: 'standard',
      defaultPlan: V5PricingOptions.V5Standard3,
      label: 'Standard',
      description: 'Everything you need to start making hires',
      featureTitle: 'Includes: ',
      features: [
        'Full access to advertising & sourcing',
        'Unlimited users, messages & hires',
        'Access to every feature & integration',
        'Instant setup & onboarding'
      ],
      button: {
        text: 'Book a demo',
        buttonStyle: 'fill_cord_gradient'
      }
    },
    enterprise: {
      plan: 'enterprise',
      defaultPlan: V5PricingOptions.V5Standard5,
      label: 'Custom',
      description: 'Built for companies hiring at scale',
      featureTitle: 'Everything from Standard, plus:',
      features: [
        'Dedicated account manager',
        'Bespoke procurement & security',
        'Invoice billing & volume discounts',
        'SLAs (Service-level agreements)'
      ],
      button: {
        text: 'Get in touch',
        buttonStyle: 'stroke_cord_gradient'
      }
    }
  },
  // Duplicate entry of UK
  [OperatingCountry.EU]: {
    standard: {
      plan: 'standard',
      defaultPlan: V5PricingOptions.V5Standard3,
      label: 'Standard',
      description: 'Everything you need to start making hires',
      featureTitle: 'Includes: ',
      features: [
        'Full access to advertising & sourcing',
        'Unlimited users, messages & hires',
        'Access to every feature & integration',
        'Instant setup & onboarding'
      ],
      button: {
        text: 'Book a demo',
        buttonStyle: 'fill_cord_gradient'
      }
    },
    enterprise: {
      plan: 'enterprise',
      defaultPlan: V5PricingOptions.V5Standard5,
      label: 'Custom',
      description: 'Built for companies hiring at scale',
      featureTitle: 'Everything from Standard, plus:',
      features: [
        'Dedicated account manager',
        'Bespoke procurement & security',
        'Invoice billing & volume discounts',
        'SLAs (Service-level agreements)'
      ],
      button: {
        text: 'Get in touch',
        buttonStyle: 'stroke_cord_gradient'
      }
    }
  },
  [OperatingCountry.US]: {
    standard: {
      plan: 'standard',
      defaultPlan: V5PricingOptions.V5Standard3,
      label: 'Standard',
      description: 'Everything you need to start making hires',
      featureTitle: 'Includes: ',
      features: [
        'Unlimited users, messages & hires',
        'Access to every feature & integration',
        'Instant setup & onboarding'
      ],
      button: {
        text: 'Book an onboard',
        buttonStyle: 'fill_cord_gradient'
      },
      smallText: '*Available only to the first 30 early-adopters hiring in SF Bay',
      allowFree: true
    },
    enterprise: {
      plan: 'enterprise',
      defaultPlan: V5PricingOptions.V5Standard5,
      label: 'Custom',
      description: 'Built for companies hiring at scale',
      featureTitle: 'Everything from Standard, plus:',
      features: [
        'Bespoke procurement & security',
        'Invoice billing & volume discounts',
        'SLAs (Service-level agreements)'
      ],
      button: {
        text: 'Get in touch',
        buttonStyle: 'stroke_cord_gradient'
      }
    }
  }
};

export const ES_SU_PROGRAM_BLOCK: BlockProps = {
  title: 'Less than 100 employees?',
  description:
    'Small companies of 100 employees or less can qualify for 40% or 20% discounts through our Early-Stage and Start-Up programmes.',
  button: { text: 'Find out more', link: '/es-su-programme' },
  background: 'light_grey'
};
