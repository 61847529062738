import React, { ReactNode, CSSProperties } from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import { AnchorClickEvent, ButtonClickEvent, Image } from 'types';
import { mobile } from 'responsiveConfig';

import Button, { Props as ButtonProps } from 'components/button';

import ConditionalRender from 'v2/components/utility/ConditionalRender';

import typography from 'css/base/_typography.module.scss';

export type TourBubbleProps = {
  className?: string;
  topImage?: Image;
  title?: ReactNode;
  body: ReactNode;
  onButtonClick?: ButtonProps['action'];
  onClose?: () => void;
  buttonText?: string;
  showNextButtonArrow?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
  align?: 'top' | 'bottom' | 'left' | 'right' | 'center';
  width?: CSSProperties['width'];
  size?: 'small' | 'large';
  show?: boolean;
  fixedOnMobile?: boolean;
  centeredOnMobile?: boolean;
  step?: number;
  totalSteps?: number;
};

export default function TourBubble({
  className,
  topImage,
  title,
  body,
  onButtonClick,
  onClose,
  buttonText,
  showNextButtonArrow,
  position,
  align,
  width,
  size,
  show,
  fixedOnMobile,
  centeredOnMobile,
  step,
  totalSteps
}: TourBubbleProps) {
  const onClick = (e: AnchorClickEvent | ButtonClickEvent | MouseEvent, type: 'next' | 'close') => {
    e.stopPropagation();
    e.preventDefault();

    if (type === 'next') onButtonClick?.();
    else onClose?.();
  };

  return (
    <BubbleWrapper
      className={ClassNames(className, 'tour_bubble_wrapper')}
      fixedOnMobile={fixedOnMobile}
      centeredOnMobile={centeredOnMobile}
      position={position}
      align={align}
      width={width}
      size={size}
      show={show}
    >
      <Bubble
        className="tour_bubble"
        align={align}
        position={position}
        centeredOnMobile={centeredOnMobile}
      >
        {onClose && (
          <Close
            className={ClassNames('icon_close', 'bubble_close')}
            onClick={e => onClick(e, 'close')}
          />
        )}
        {topImage && <img className="bubble_image" {...topImage} alt={topImage.alt} />}
        {title && <Title className="bubble_title">{title}</Title>}
        <Body className="bubble_body">{body}</Body>
        {onButtonClick && (
          <Button
            text={buttonText}
            className="bubble_button"
            buttonStyle="stroke_white"
            action={e => onClick(e!, 'next')}
            {...(showNextButtonArrow
              ? {
                  icon: 'icon_arrow_right',
                  iconPosition: 'right'
                }
              : {})}
          />
        )}
        <ConditionalRender predicate={step}>
          <StepCounter>
            Step {step} of {totalSteps}
          </StepCounter>
        </ConditionalRender>
      </Bubble>
    </BubbleWrapper>
  );
}

const BubbleWrapper = styled.div<Omit<TourBubbleProps, 'body'>>`
  position: absolute;
  font-family: ${typography.fontFamily}, Helvetica, Arial, sans-serif;
  z-index: -10;
  bottom: ${({ position, align }) =>
    position === 'top' ? 'calc(100% + 20px)' : align === 'bottom' ? '-22px' : 'inherit'};
  top: ${({ position, align }) =>
    position === 'bottom' ? 'calc(100% + 10px)' : align === 'top' ? '-22px' : 'inherit'};
  left: ${({ position, align }) =>
    align === 'left' ? '0' : position === 'right' ? 'calc(100% + 25px)' : 'inherit'};
  right: ${({ position, align }) =>
    align === 'right'
      ? '0'
      : align === 'center'
      ? 'calc(50% - 38px)' //factoring in the padding and the triangle size (25 padding + 13 half triangle)
      : position === 'left'
      ? 'calc(100% + 25px)'
      : 'inherit'};
  width: ${({ width, size }) =>
    width || (size === 'large' ? '380px' : size === 'small' ? '220px' : '320px')};
  text-align: left;
  opacity: 0;
  line-height: 1;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  max-width: 0;
  max-height: 0;
  text-transform: none;
  pointer-events: none;

  .button.with_icon .icon_arrow_right {
    font-size: ${({ theme }) => theme.typography.normal} !important;
    font-weight: ${({ theme }) => theme.typography.black};
    padding-bottom: 2px;
  }

  @media only screen and (max-width: ${mobile}) {
    width: 80vw;

    .bubble_image {
      width: 100%;
    }

    ${({ centeredOnMobile }) =>
      centeredOnMobile &&
      css`
        width: 96%;
        left: 2%;
      `};

    ${({ fixedOnMobile }) =>
      fixedOnMobile &&
      css`
        position: fixed;
        bottom: 5px;
        right: inherit;
        top: inherit;
        width: 96%;
        left: 2%;

        .tour_bubble:before {
          display: none;
        }
      `};
  }

  ${({ show }) =>
    show &&
    css`
      pointer-events: auto;
      opacity: 1;
      z-index: 102;
      overflow: initial;
      max-width: initial;
      max-height: fit-content;
    `}
`;

const Bubble = styled.div<Omit<TourBubbleProps, 'body'>>`
  background-color: ${({ theme }) =>
    theme.darkMode ? theme.colours.primaryColour : theme.colours.fontColour};
  color: white;
  padding: 25px;
  border-radius: 20px;
  box-shadow: 0 4px 32px -4px ${({ theme }) => (theme.darkMode ? theme.colours.darkDropshadow : theme.colours.dropshadow)};
  position: relative;

  .bubble_close {
    color: white;
  }

  .bubble_image {
    margin-bottom: 1rem;
  }

  strong {
    font-weight: ${typography.bold};
  }

  .stroke_white {
    min-width: 80px;
  }

  .button {
    margin-top: 20px;
  }

  .inline_icon {
    font-size: 24px;
    vertical-align: bottom;
  }

  &:before {
    position: absolute;
    content: '';
    width: 26px;
    height: 26px;
    transform: rotate(45deg);
    background-color: ${({ theme }) =>
      theme.darkMode ? theme.colours.primaryColour : theme.colours.fontColour};
    top: ${({ position, align }) =>
      position === 'bottom' ? '-10px' : align === 'top' ? '25px' : 'inherit'};
    bottom: ${({ position, align }) =>
      position === 'top' ? '-10px' : align === 'bottom' ? '25px' : 'inherit'};
    left: ${({ position, align }) =>
      align === 'left'
        ? '25px'
        : position === 'right'
        ? '-10px'
        : position === 'left'
        ? 'calc(100% - 15px)'
        : 'calc(100% - 53px)'};
    border-radius: 4px;

    ${({ centeredOnMobile }) =>
      centeredOnMobile &&
      css`
        @media only screen and (max-width: ${mobile}) {
          left: calc(50% - 13px);
        }
      `};
  }
`;

const Close = styled.button`
  position: absolute !important;
  right: 15px;
  top: 15px;
  padding: 5px;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }
`;

const Title = styled.h3`
  margin: 0 0 15px;
`;

const Body = styled.div`
  font-weight: ${({ theme: { typography } }) => typography.regular};
  font-size: 16px;
  line-height: 1.4;
`;

const StepCounter = styled.div`
  display: inline-block;
  position: absolute;
  right: 25px;
  bottom: 40px;
  font-size: ${({ theme }) => theme.typography.small};
  font-weight: ${({ theme }) => theme.typography.bold};
  opacity: 0.6;
`;
