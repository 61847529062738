import { History } from 'history';
import { useHistory } from 'react-router';

import { createAsyncThunk } from '@reduxjs/toolkit';

import { getLocalStorageItem, removeLocalStorageItem } from 'cookieManager';
import { getProviderCode } from 'fetcher';
import { AppDispatch, RootState, setLoader, setProfile, setUserType, useAppDispatch } from 'store';
import {
  fetchCompanyData,
  fetchCandidateData,
  fetchAdminData,
  checkPWAOpened
} from 'store/services';
import { getQueryStringObject } from 'utils';
import { UserProfileType } from 'types';

const evaluateQueryParamsForRedirect = async (queryParams: {
  client_id: number;
  redirect_uri?: string;
}): Promise<boolean> => {
  const location = window.location;
  const { status, data } = await getProviderCode(queryParams.client_id);

  if (status === 'success') {
    const { code, redirectURI } = data;

    location.href = `${redirectURI}?code=${code}`;
    return true;
  } else if (queryParams.redirect_uri) {
    location.href = queryParams.redirect_uri;
    return true;
  } else {
    return false;
  }
};

export const updateLoginStatus = createAsyncThunk<
  void,
  History<unknown>,
  { dispatch: AppDispatch; state: RootState }
>('services/common/updateloginstatus', async (history, { dispatch, getState }) => {
  const state = getState();
  const { main: loading } = state.ui.loaders['/'];

  const newUserType = getLocalStorageItem<UserProfileType>('role');
  const requestedURL = getLocalStorageItem('requestedURL');

  const location = window.location;
  const queryParams = location?.search
    ? (getQueryStringObject(location.search) as { client_id: number } | null)
    : null;

  const classes = [];

  if (!loading) dispatch(setLoader({ value: true }));

  switch (newUserType) {
    case 'company': {
      let shouldGetData = true;
      if (queryParams?.client_id) {
        const isRedirect = await evaluateQueryParamsForRedirect(queryParams);
        if (isRedirect) shouldGetData = false;
      }

      if (shouldGetData) await dispatch(fetchCompanyData(true));

      classes.push('company');
      break;
    }
    case 'candidate': {
      await dispatch(fetchCandidateData(true));
      classes.push('candidate');
      break;
    }
    case 'admin': {
      await dispatch(fetchAdminData());
      classes.push('admin');
      break;
    }
    default:
      removeLocalStorageItem('role');
      dispatch(setUserType(null));
      dispatch(setProfile(null));

      dispatch(checkPWAOpened(null));
      break;
  }

  dispatch(setLoader({ value: false }));

  if (classes.length > 0) document.body.classList.add(...classes);

  if (requestedURL && newUserType) {
    history.push(requestedURL);
    removeLocalStorageItem('requestedURL');
  }
});

export const useUpdateLoginStatus = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  return async () => {
    dispatch(updateLoginStatus(history));
  };
};

export type UpdateLoginStatus = typeof updateLoginStatus;
