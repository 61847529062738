import React from 'react';

import { ESSUProgram, ESSUProgramList } from './FounderProgramPage.styled';

export const getLeftColumnProps = (
  searchParams: Record<string, string>,
  isESSUProgramPage: boolean,
  isSanFranPage: boolean
) => {
  if (isESSUProgramPage) {
    return {
      cta: 'EARLY-STAGE AND START-UP PROGRAMMES',
      title: 'Helping smaller companies make direct hires',
      subtitle:
        'Make direct hires at a significant discount via our Early-Stage and Start-Up Programmes:',
      children: (
        <ESSUProgramList>
          <ESSUProgram
            title="40% discount for companies under 25 employees"
            iconCircleColour="light"
          />
          <ESSUProgram
            title="20% discount for companies between 26-100 employees"
            iconCircleColour="light"
          />
        </ESSUProgramList>
      )
    };
  }

  if (isSanFranPage) {
    const { companyName } = searchParams;
    const yourCompany = companyName ? ` for ${companyName}` : '';
    return {
      cta: 'SAN FRANCISCO EARLY ADOPTERS PROGRAM',
      title: `Get free access${yourCompany}`,
      subtitle:
        'cord is launching in the San Francisco Bay Area. The first 30 early adopters will pay nothing for two months. No commitment. No hiring fees.',
      children: (
        <ESSUProgramList>
          <ESSUProgram
            title="Be the first to access active engineers looking for work"
            iconCircleColour="light"
          />
          <ESSUProgram
            title="Get over 60% positive responses to your messages"
            iconCircleColour="light"
          />
          <ESSUProgram
            title="Have a direct influence on the product direction"
            iconCircleColour="light"
          />
        </ESSUProgramList>
      )
    };
  }

  return {
    cta: 'APPLY FOR FOUNDER PROGRAM',
    title: 'Helping Early-Stage companies hire their first positions',
    subtitle:
      'Start-ups of under 25 employees can apply for our Founder Program and use cord with a 25% discount.'
  };
};

export const getQuote = (isESSUProgramPage: boolean, isSanFranPage: boolean) => {
  if (isESSUProgramPage) {
    return {
      title: 'Why do the programmes exist?',
      subTitle:
        '"Hiring your early team is both a joy and a challenge. Our Early Stage and Start-Up Programmes gives smaller companies an affordable way to access cord so they can build their teams."'
    };
  }
  if (isSanFranPage) {
    return {
      title: 'Why does the program exist?',
      subTitle: `"As we launch in San Francisco Bay, we're deeply investing in a small group of early adopters. You'll inform our product direction, and in return get free access to a new tool and talent pool.”`
    };
  }

  return {
    title: 'Why Founder Program?',
    subTitle: (
      <i>
        "Hiring your first few people is both a joy and a challenge. Our Founder Program gives early
        stage companies full access to cord so they can build their teams."
      </i>
    )
  };
};

export const getSEOContent = (isESSUProgramPage: boolean, isSanFranPage: boolean) => {
  if (isESSUProgramPage) {
    return {
      title: 'Early-Stage & Start-Up Discount Programmes',
      description:
        'We invest in the future of tech by enabling smaller companies make hires at a price they can afford through our Early-Stage and Start-Up Programmes.'
    };
  }
  if (isSanFranPage) {
    return {
      title: 'San Francisco Early Adopters Program - Free for 2 months',
      description: `We're launching in the San Francisco Bay Area and the first 30 early adopters get free access to cord for their first 2 months. No commitment. No hiring fees.`
    };
  }

  return {
    title: 'Founder Program - 25% Discount',
    description:
      'The founder program helps early-stage companies of under 20 employees hire their first positions on cord by offering them full access to all features on cord with a 25% discount.'
  };
};
