import { CompanySubscriptionPlan, Frequency, OperatingCountry } from '@cohiretech/common-types';

import {
  CurrentPlanDetails,
  PayPerHirePricingOptions,
  PricingCountry,
  RequiredToOptional,
  SelectedPricing,
  SubscriptionPlan
} from 'types';
import { CHECKOUT_URL_PATHS, V5_PRICING_OPTIONS } from 'consts';
import { commify, getCurrencyFromPricingCountry } from 'utils';
import { trackCreateAccountEvents } from 'tracking-utils';
import { trackExternalCompanyActivate } from 'v2/services/fetchers/company/tracking';

import { Props as ButtonProps } from 'components/button';

export const checkIfSourcingBasic = (plan?: SubscriptionPlan) =>
  plan === CompanySubscriptionPlan.Basic;

export const isNewPricingPlanV5 = (subscriptionPlan?: SubscriptionPlan) =>
  subscriptionPlan ? (V5_PRICING_OPTIONS as SubscriptionPlan[]).includes(subscriptionPlan) : false;

export const isPayPerHirePricingPlan = (subscriptionPlan?: SubscriptionPlan) =>
  subscriptionPlan === PayPerHirePricingOptions.PayPerHire;

export const getRequestDemoLink = ({
  plan,
  pricingCountry,
  frequency
}: RequiredToOptional<SelectedPricing, 'frequency'>): Required<ButtonProps>['link'] => {
  if (pricingCountry === OperatingCountry.US) {
    return {
      pathname: '/san-francisco'
    };
  }
  return {
    pathname: '/request-demo',
    search: `?plan=${plan}`,
    state: { from: window?.location?.pathname, pricingType: pricingCountry, period: frequency }
  };
};

export const getCheckoutURLPath = ({ plan, frequency }: SelectedPricing) => {
  const checkoutPath = CHECKOUT_URL_PATHS[plan as keyof typeof CHECKOUT_URL_PATHS];

  if (frequency === Frequency.annual && checkoutPath && !checkoutPath.endsWith('-annual')) {
    return `${checkoutPath}-annual`;
  }

  return checkoutPath;
};

export const getActivateBtnProps = (
  { plan, frequency, pricingCountry }: SelectedPricing<keyof typeof CHECKOUT_URL_PATHS>,
  isUnpaidAccount?: boolean
): ButtonProps => ({
  text: 'Activate account',
  link: { pathname: `/${pricingCountry.toLowerCase()}/checkout/${CHECKOUT_URL_PATHS[plan]}` },
  action: () => {
    trackCreateAccountEvents(`${plan} | ${frequency} | ${pricingCountry}`, 'click_to_activate');
    if (isUnpaidAccount) trackExternalCompanyActivate();
  }
});

export const getDisplayPrice = (pricingCountry: PricingCountry, price: number = 0) => {
  const currency = getCurrencyFromPricingCountry(pricingCountry);
  return `${currency}${commify(Math.round(price))}`;
};

type GetDisplayPriceProps = {
  pricingCountry: PricingCountry;
  price?: number;
  discountedPrice?: number;
  allowFree?: boolean;
};

export const getDisplayPriceSetup = ({
  price,
  discountedPrice,
  pricingCountry,
  allowFree
}: GetDisplayPriceProps) => {
  let originalPrice = '';
  let displayPrice = getDisplayPrice(pricingCountry, price);

  if (allowFree && discountedPrice === 0) {
    originalPrice = getDisplayPrice(pricingCountry, price);
    displayPrice = 'Free';
  } else if (discountedPrice) {
    originalPrice = getDisplayPrice(pricingCountry, price);
    displayPrice = getDisplayPrice(pricingCountry, discountedPrice);
  }

  return { originalPrice, displayPrice };
};

const getPlanTypeFromURLPath = (urlPath: string = '') => {
  const urlPathArr = urlPath.split('-');
  return urlPathArr.slice(0, 3).join('-');
};

export const isCurrentPlanSelected = (
  selectedPricing: SelectedPricing,
  currentPlanDetails?: CurrentPlanDetails
) => {
  const { plan, frequency } = selectedPricing;
  const { plan: currentPlan, billingFrequency, urlPath } = currentPlanDetails || {};
  const checkoutPath = getCheckoutURLPath(selectedPricing);

  // First check if plan is same by comparing name and frequency
  const isSameFrequency = frequency === billingFrequency;
  const isSamePlan = plan === currentPlan && isSameFrequency;
  if (isSamePlan) return true;

  // Second check if plan is same by comparing urlPath
  const isSameTypeOfPlan = getPlanTypeFromURLPath(urlPath) === getPlanTypeFromURLPath(checkoutPath);
  const isSameURLPath = checkoutPath && urlPath && isSameTypeOfPlan && isSameFrequency;

  return isSameURLPath;
};
