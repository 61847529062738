export const decodeCompanyMessageDetails = ({
  expSalaryMin,
  photoUrl,
  linkedinUrl,
  githubUrl,
  stackUrl,
  websiteUrl,
  behanceUrl,
  remoteWork,
  education,
  occupation,
  achievement,
  publication,
  project,
  relocateLocations,
  ...other
}: $TSFixMe) => {
  return {
    salaryMin: expSalaryMin,
    relocateLocations:
      relocateLocations &&
      relocateLocations.map((location: $TSFixMe) => location.replace(' (require visa)', '')),
    photoURL: photoUrl,
    linkedin: linkedinUrl || '',
    github: githubUrl || '',
    stackOverflow: stackUrl || '',
    website: websiteUrl || '',
    behance: behanceUrl || '',
    remote: remoteWork,
    educations: education.map((e: $TSFixMe) => {
      return {
        id: e.id,
        institution: e.institution,
        subject: e.subject,
        grade: e.grade,
        startYear: e.startDate ? new Date(e.startDate).getFullYear() : -1,
        endYear: e.endDate ? new Date(e.endDate).getFullYear() : -1,
        description: e.summary
      };
    }),
    experiences: occupation.map((o: $TSFixMe) => {
      return {
        id: o.id,
        company: o.company,
        position: o.position,
        location: o.location,
        description: o.summary,
        current: o.current,
        startMonth: o.startDate ? new Date(o.startDate).getMonth() : -1,
        startYear: o.startDate ? new Date(o.startDate).getFullYear() : -1,
        endMonth: o.endDate ? new Date(o.endDate).getMonth() : -1,
        endYear: o.endDate ? new Date(o.endDate).getFullYear() : -1,
        companySizes: o.companySizes,
        companyIndustries: o.companyIndustries
      };
    }),
    projects: project.map((p: $TSFixMe) => {
      return {
        id: p.id,
        title: p.title,
        url: p.url,
        description: p.description,
        current: p.current,
        startMonth: p.startDate ? new Date(p.startDate).getMonth() : -1,
        startYear: p.startDate ? new Date(p.startDate).getFullYear() : -1,
        endMonth: p.endDate ? new Date(p.endDate).getMonth() : -1,
        endYear: p.endDate ? new Date(p.endDate).getFullYear() : -1
      };
    }),
    awards: achievement.map((a: $TSFixMe) => {
      return {
        id: a.id,
        title: a.title,
        description: a.description,
        issuer: a.issuer,
        month: a.achieveDate ? new Date(a.achieveDate).getMonth() : -1,
        year: a.achieveDate ? new Date(a.achieveDate).getFullYear() : -1
      };
    }),
    publications: publication.map((p: $TSFixMe) => {
      return {
        id: p.id,
        title: p.title,
        publisher: p.publisher,
        url: p.url,
        authors: p.authors,
        description: p.description,
        month: p.publishDate ? new Date(p.publishDate).getMonth() : -1,
        year: p.publishDate ? new Date(p.publishDate).getFullYear() : -1
      };
    }),
    ...other
  };
};
