import { ReactNode } from 'react';

import {
  CompanySuspensionInterface,
  CompanyInterface,
  CompanyAccountInterface,
  CompanyRole,
  ListingRawInterface,
  FeedInterface,
  NotificationResult,
  StoryInterface,
  ApplicationStatus,
  ApplicationMatchScoreInterface,
  AgencyClientCompanyInterface,
  CompanyAccountShortlistInterface,
  CompanyAccountOutput
} from '@cohiretech/common-types';

import { UsageStatistics, InactivePlan } from './pricing';
import { OptionalToRequired } from './utils';

export interface CompanyProfile extends Required<CompanyInterface> {
  profileType: 'company';
  positions: PositionInProfile[];
  companySuspension: CompanySuspensionInterface | null;
  isCandidate?: boolean;
  companyUser: CompanyAccountInterface & { onboardingChecklist?: CompanyOnboardingChecklist };
  shortlists: CompanyAccountShortlistInterface[];
  companyStreams: FeedInterface[];
  notifications: Array<NotificationResult & { associated?: boolean }>;
  totalNotifications: number;
  associatedSelfTotalNotifications: number;
  members: Member[];
  payAsYouGo: boolean;
  removeUnconsciousBiasOverride: boolean;
  planUsage: UsageStatistics;
  inactivePlan: InactivePlan | undefined;
  isUnpaidAccount: boolean;
}

export type Member = OptionalToRequired<CompanyAccountOutput, 'id' | 'accountID'> & {
  memberName: NonNullable<CompanyAccountOutput['accountName']>;
  jobTitle: NonNullable<CompanyAccountOutput['jobTitle']>;
  photoURL: NonNullable<CompanyAccountOutput['accountPhotoURL']>;
  lastActive: NonNullable<CompanyAccountOutput['accountLastActive']>;
};

export type CompanyUser = CompanyProfile['companyUser'];

export interface PositionInProfile extends Required<ListingRawInterface> {
  members: number[];
  isVisaSponsorshipAvailable: boolean;
  relevantSkills: string[];
  recentConvoStartedCount: number;
  recentRequestsReceivedCount: number;
  outstandingRequestCount: number;
  lastActive: Date;
}

export type PrimaryMember = PositionInProfile['primaryMember'];

export type CompanyRoleOption = {
  value: CompanyRole;
  label: string | (string | ReactNode)[];
  restricted: boolean;
  disabled?: boolean;
};

export type CompanyOnboardingItem =
  | 'profile'
  | 'position'
  | 'stream'
  | 'ats'
  | 'calendar'
  | 'template'
  | 'message'
  | 'guide';
type CompanyOnboardingChecklist = { [key in CompanyOnboardingItem]: boolean };

export enum IntegrationCategory {
  ATS = 'ats',
  Notification = 'notification',
  Calendar = 'calendar',
  All = 'all'
}

export enum IntegrationCategoryName {
  ATS = 'Application Tracking System (ATS)',
  Notification = 'Messages & Notifications',
  Calendar = 'Calendar'
}

export type IntegrationItem = {
  name: string;
  slug?: string;
  logo: any;
  description: string;
  connected: boolean;
  subdomain?: string;
  showNewLabel?: boolean;
  beta?: boolean;
};

export type IntegrationType = {
  id: string;
  category: IntegrationCategoryName;
  title?: string;
  description?: string;
  active?: boolean;
  items: IntegrationItem[];
  locked?: boolean;
};

export type AdminCustomerStory = StoryInterface & { companyName: string };

export type SpecialEnquiryFormType = {
  firstName?: string;
  lastName?: string;
  email?: string;
  jobTitle?: string;
  companyName?: string;
  companyWebsite?: string;
  confirm?: boolean;
};

export type RequestDemoForm = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  phoneCountryCode?: number;
  password?: string;
  name?: string;
  jobType?: string;
};

export type InputAgencyClientCompany = Omit<AgencyClientCompanyInterface, 'id'> & { id?: number };

export interface CompanyApplicationMessage {
  positionName: string;
  pictureURLs: string[];
  companyID: number;
  candidateID: number;
  firstName: string;
  lastName: string;
  photoURL: string;
  lastActive: Date;
  companyName: string;
  listingID: number;
  applicationID: number;
  candidateSeen: boolean;
  applicationStatus: ApplicationStatus;
  companyArchived: boolean;
  companyHired: boolean;
  seen: boolean;
  date: Date;
  expired: boolean;
  applicationMessageID: number;
  peopleInThread: Record<string, any>[];
  candidateMatchOnApply: number;
  currentCompany: string;
  currentPosition: string;
  location: string;
  primarySkills: string[];
  seniorities: string[];
  expectedSalary: number;
  applicationMatchScore: ApplicationMatchScoreInterface;
  messagePreview: string;
}
