import axios from 'axios';

import { ApplicationSmartDeclineMessageOptions } from '@cohiretech/common-types';

import { APIResponse, CompanyApplicationMessage, MessageThread } from 'types';
import { decodeCompanyMessageDetails } from 'v2/services/company/messages';

import { axiosGetWithID } from '../axiosWithRequestID';

const COMPANY_CANDIDATE_MESSAGE = process.env.REACT_APP_COMPANY_CANDIDATE_MESSAGE;
const COMPANY_CANDIDATE_DECLINE = process.env.REACT_APP_COMPANY_CANDIDATE_DECLINE!;

export type GetMessagePreviewSortBy =
  | 'importance'
  | 'created_date_desc'
  | 'created_date_asc'
  | 'best_fit'
  | 'unread'
  | 'position';

type GetMessagesProps = {
  messageCategory: string;
  listingID: number[];
  messageFilter: string;
  searchTerm?: string;
  page?: number;
  sortBy?: GetMessagePreviewSortBy;
  requestId?: string;
  isAgency: boolean;
};

type GetMessagesResponse = APIResponse<{
  hasNextPage: boolean;
  paginatedRows: CompanyApplicationMessage[];
}>;

export const shouldIgnoreMessageFilter = (category: string, isAgency: boolean) =>
  category === 'incomingRequest' && !isAgency;

export const getMessagePreviews = async ({
  messageCategory,
  listingID,
  messageFilter: msgFilter,
  searchTerm = '',
  page = 0,
  sortBy,
  requestId = `company_message_previews_${messageCategory}_${msgFilter}_${page}_${sortBy}}`,
  isAgency
}: GetMessagesProps): Promise<GetMessagesResponse> => {
  try {
    const url = `${COMPANY_CANDIDATE_MESSAGE}`;
    const messageFilter = shouldIgnoreMessageFilter(messageCategory, isAgency) ? '' : msgFilter;

    const params = { messageCategory, searchTerm, listingID, messageFilter, page, sortBy };
    const { data } = await axiosGetWithID<GetMessagesResponse>({ url, params, requestId });
    if (!data?.data) throw new Error('No data');
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

type GetMessageDetailsProps = {
  listingID: number;
  candidateID: number;
  requestId?: string;
};

export const getMessageDetails = async ({
  listingID,
  candidateID,
  requestId = `company_message_details_${listingID}_${candidateID}}`
}: GetMessageDetailsProps): Promise<APIResponse<MessageThread>> => {
  const url = `${COMPANY_CANDIDATE_MESSAGE}/${listingID}/${candidateID}`;
  try {
    const { data } = await axiosGetWithID<APIResponse<MessageThread>>({ url, requestId });
    // Todo: Do we still need decodeCompanyMessageDetails?
    const parsedData = decodeCompanyMessageDetails(data?.data || {});
    return { status: 'success', data: parsedData };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};

type DeclineCandidateParams = {
  listingID: number;
  candidateID: number;
  message: string;
  templateID?: number;
  declineReason: string;
  aiGenerated: boolean;
  options?: Omit<ApplicationSmartDeclineMessageOptions, 'reason'> & {
    originalMessage?: string;
  };
};

export const declineCandidate = async (params: DeclineCandidateParams): Promise<APIResponse> => {
  try {
    await axios.post(COMPANY_CANDIDATE_DECLINE, params);
    return { status: 'success' };
  } catch (error: any) {
    return { status: 'failure', message: error?.response?.data?.message };
  }
};
