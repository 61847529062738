import React from 'react';
import styled from 'styled-components';

import Button from 'components/button';
import ErrorMessage from 'components/errormessage';
import Checkbox from 'components/checkbox';

import ScoreInput from './QualityScoreField';

type State = {
  qualityScore?: number;
  errorMessage: string;
  validScore: boolean;
  needsBuilding: boolean;
};

interface Props {
  onSubmit: (data: { qualityScore?: number; verify: boolean }) => void;
}

export function isValidQualityScore(score?: number) {
  return score && Number.isInteger(score) && score >= 1 && score <= 10;
}

export default class CandidateQualityScoreForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      qualityScore: undefined,
      errorMessage: '',
      validScore: false,
      needsBuilding: true
    };
  }

  handleScoreChange = (score: number | undefined) => {
    this.validateScore(score);
    this.setState({ qualityScore: score });
  };

  validateScore(score: number | undefined) {
    if (!isValidQualityScore(score)) {
      return this.setState({
        errorMessage: 'Quality score must be an integer between 1 and 10.',
        validScore: false
      });
    }

    this.setState({ errorMessage: '', validScore: true });
  }

  onSubmit() {
    const { qualityScore, needsBuilding } = this.state;
    this.setState({ errorMessage: '' });

    this.validateScore(qualityScore);

    if (!this.state.validScore) return;

    const { onSubmit } = this.props;
    onSubmit({ qualityScore, verify: !needsBuilding });
  }

  render() {
    const { qualityScore, errorMessage, needsBuilding } = this.state;

    return (
      <Form className="candidate_info_form">
        <Field>
          <ScoreInput value={qualityScore} onChange={this.handleScoreChange} />
        </Field>
        <Checkbox
          checkboxID="needs_building_checkbox"
          checked={needsBuilding}
          label="Send to build queue"
          onChange={checked => this.setState({ needsBuilding: checked })}
        />
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
        <Button
          disabled={!this.state.validScore}
          text="Save score"
          className="save_info_button"
          action={() => this.onSubmit()}
        />
      </Form>
    );
  }
}

const Form = styled.div`
  position: relative;
  text-align: right;

  .checkbox_wrapper {
    text-align: left;
  }
`;

const Field = styled.div`
  display: block;
  text-align: left;
  margin-bottom: 10px;

  .autocomplete_cont .search_input,
  input {
    margin: 0;
  }
`;
