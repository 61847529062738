import React from 'react';
import ClassNames from 'classnames';
import styled, { css } from 'styled-components';

import media from 'styles/media';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import ArrowLink from 'components/arrowlink';
import BulletList, { Bullet } from 'components/bulletlist';
import Button from 'components/button';
import ErrorMessage from 'components/errormessage';
import LinkWithIcon, { LinkWithIconProps } from 'components/linkwithicon';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import Spacing from 'v2/components/ui/styles/Spacing';

import typography from 'css/base/_typography.module.scss';

import { usePricingButtons } from './PricingPlanCard.hooks';
import { PricingPlanCardProps } from './PricingPlanCard.helpers';
import PlanPrice from './PlanPrice';

const CONFIRMATION_MESSAGE = 'A member of the team will be in touch soon.';

export default function PricingPlanCard(props: PricingPlanCardProps) {
  const isMobile = useMedia([mediaQueries.mobile]);
  const { button, secondaryLink, errorMessage } = usePricingButtons(props);

  const { pricingOption, selectedPricing, selectedTier, hidePrice } = props;
  const { plan, label, description, featureTitle, features, smallText } = pricingOption;
  const isEnterprise = plan === 'enterprise';

  return (
    <PricingPlan
      className={ClassNames(plan, 'pricing_plan_card', {
        small_text: !!smallText,
        hide_price: hidePrice
      })}
    >
      <PlanInfo>
        <h3 className="plan_title">{label}</h3>
        <p className="plan_description">{description}</p>
      </PlanInfo>
      <ConditionalRender
        predicate={!hidePrice}
        fallback={<Spacing height={isEnterprise ? '06' : '08'} />}
      >
        <PlanPrice selectedPricing={selectedPricing} plan={selectedTier} />
      </ConditionalRender>
      <Bottom
        className={ClassNames({
          small_text: !!smallText,
          hide_price: hidePrice,
          enterprise: isEnterprise
        })}
      >
        <FeatureList>
          <h4>{featureTitle}</h4>
          {features.map((feature, idx) => (
            <Bullet key={`${plan}_feature_${idx}`} iconCircleColour="light">
              {feature}
            </Bullet>
          ))}
        </FeatureList>
        <ButtonWrapper className="plan_button_wrapper">
          <Button {...button} className={ClassNames({ eighteen_px_font: isMobile })} />
          <ConditionalRender predicate={secondaryLink}>
            <ConditionalRender
              predicate={!secondaryLink?.icon}
              fallback={<LinkWithIcon {...(secondaryLink as LinkWithIconProps)} />}
            >
              <CheckoutLink to={secondaryLink?.link} onClick={secondaryLink?.action}>
                {secondaryLink?.text}
              </CheckoutLink>
            </ConditionalRender>
          </ConditionalRender>
        </ButtonWrapper>
      </Bottom>
      <ConditionalRender predicate={smallText && !hidePrice}>
        <SmallText>{smallText}</SmallText>
      </ConditionalRender>
      <ConditionalRender predicate={hidePrice}>
        <PricingHint isEnterprise={isEnterprise} />
      </ConditionalRender>
      <ConditionalRender predicate={errorMessage || (!isEnterprise && selectedTier?.enquired)}>
        <ErrorMessage type={errorMessage ? 'critical' : undefined}>
          {errorMessage || CONFIRMATION_MESSAGE}
        </ErrorMessage>
      </ConditionalRender>
    </PricingPlan>
  );
}

const PricingHint = ({ isEnterprise }: { isEnterprise: boolean }) => {
  const text = isEnterprise
    ? 'Bespoke pricing based on your needs.'
    : 'Pay monthly. Cancel anytime.';

  return <PricingHintText className="blue_gradient_text">{text}</PricingHintText>;
};

const PricingPlan = styled.div`
  ${({ theme: { colours, darkMode } }) => css`
    width: 50%;
    padding: 1.75rem;
    box-sizing: border-box;
    background: ${darkMode ? colours.darkBgColour : colours.white};
    box-shadow: 0 0 30px ${colours[darkMode ? 'darkDropshadow' : 'dropshadow']};
    border-radius: 1.5rem;

    height: 416px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.enterprise {
      height: 380px;
      padding: var(--spacing-07);
      background: ${darkMode ? '#283d54' : colours.fontColour};
      color: ${colours.white};
      box-shadow: 0 0 1.25rem rgba(49, 71, 95, 0.1);
    }

    &.hide_price {
      height: 388px;
      justify-content: flex-start;

      &.enterprise {
        height: 352px;
      }
    }
  `}

  ${media.mobile`
    width: 100% !important;

    &.growth .button {
      width: 100%;
    }
  `}

  ${media.mobileMin`
    &.standard {
      padding: 2.25rem;

      &.small_text {
        padding: var(--spacing-08) var(--spacing-09) var(--spacing-06) var(--spacing-09);
      }
    }

    &.enterprise {
      border-radius: 0 1.5rem 1.5rem 0;
    }
  `}
`;

const PricingHintText = styled.span`
  margin-top: var(--spacing-03);

  ${({ theme: { typography } }) => css`
    font-size: ${typography.small};
    font-weight: ${typography.semibold};
  `}
`;

const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-01);

  .plan_title {
    font-size: ${typography.header};
  }

  .plan_title,
  .plan_description {
    margin: 0;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-09);

  &.hide_price {
    gap: var(--spacing-10);
  }

  &.enterprise {
    gap: var(--spacing-07);
  }

  &.small_text {
    gap: var(--spacing-06);
  }
`;

const FeatureList = styled(BulletList)`
  ${({ theme: { typography } }) => css`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-01);

    h4 {
      font-size: ${typography.normal};
      font-weight: ${typography.semibold};
    }

    .bullet_item {
      padding-bottom: 0;
      align-items: center;

      .icon_bullet {
        padding-right: 0.5rem;
        font-size: ${typography.normal};
      }

      .bullet_content p {
        margin: 0;
      }

      ${media.mobile`
        .icon_bullet {
          font-size: ${typography.normal};
        }
      `}
    }
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  align-items: center;

  ${({ theme: { media } }) => media.mobile`
    flex-direction: column;
    gap: 1.25rem;

    .button {
      width: 100%;
    }
  `}
`;

const CheckoutLink = styled(ArrowLink)`
  &.arrow_link {
    margin: 0;

    ${({ theme: { media, typography } }) => media.mobileMin`
      font-size: ${typography.normal};

      .icon_arrow_right {
        font-size: ${typography.normal};
      }
  `}
  }
`;

const SmallText = styled.p`
  margin: 0;
  font-size: var(--type-xs);
  color: var(--text-body-tertiary);
  font-style: italic;
`;
