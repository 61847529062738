import axios from 'axios';

import {
  CandidateSignupVerificationInterface,
  CandidateSignupVerificationStatus,
  AdminCandidateSearchOrder
} from '@cohiretech/common-types';

import { APIResponse } from 'types';

type CandidateSignupReturnType = {
  results: CandidateSignupVerificationInterface[];
  resultsCount: number;
};

type CandidateSignupParams = Partial<{
  page: number;
  order: AdminCandidateSearchOrder;
  adminIDs: number[];
  onlyUnclaimed: boolean;
  search: string;
}>;

const ADMIN_CANDIDATE_SIGNUP = process.env.REACT_APP_ADMIN_CANDIDATE_SIGNUP;

export const getCandidatesSignedUp = async (
  verificationStatus: CandidateSignupVerificationStatus,
  options: CandidateSignupParams = {}
): Promise<APIResponse<CandidateSignupReturnType>> => {
  const {
    page = 0,
    order = AdminCandidateSearchOrder.OldestFirst,
    adminIDs,
    onlyUnclaimed = false,
    search
  } = options;

  const params = {
    page,
    order,
    filter_admin_id: adminIDs,
    filter_only_unclaimed: onlyUnclaimed,
    filter_keyword: search
  };

  try {
    const { data } = await axios.get<{ data: CandidateSignupReturnType }>(
      `${ADMIN_CANDIDATE_SIGNUP}/${verificationStatus}`,
      { params }
    );
    return { status: 'success', data: data.data };
  } catch (error: any) {
    return { status: 'failure', message: error?.message };
  }
};
