// Could put these into separate files like other utils structure?
import structuredClone from '@ungap/structured-clone';

import {
  Dimensions,
  ImageDimensionsType,
  LayoutType,
  ImageResizeParam,
  ResizeMode,
  ExtendedMessage
} from 'types';
import { S3_IMAGE_BUCKET_URL } from 'consts';
import { getDeviceType, isEmpty, isObject_Deprecated } from 'utils';
import { percentIncrease, percentDecrease } from 'utils/math';
import { extractS3Key } from 'utils/url';

// Should refactor this directory and make this a proper index file
export * from './imageParams';

export const IMAGE_CDN_URL = 'https://d5pksorxr4arm.cloudfront.net';
export const TEST_IMAGE_URL = `${S3_IMAGE_BUCKET_URL}/mock-messenger-photos/woman.jpg`;

export const getPictureType = (item: $TSFixMe) => {
  const imageType = ['picture_urls', 'pictureURLs', 'pictureUrls', 'coverPhotoURL'];

  for (const type of imageType) {
    if (item[type]) return type;
  }

  return false;
};

/**
 *
 * @param {ImageDimensionsType} layout type of page layout, can be view-page (pages such as CompanyProfile, PositionProfile, etc.),
 * logo (round logo images) or
 * logo-large (larger ones such as a person profile page when logged as a company).
 * columns (columns-1, solumns-2 or columns-3)
 * @description a function which takes either a page layout or number of columns, calculates the device type based ont
 * the screen width and returns a string containing picture dimentions relative to the device and page.
 */
export const getImageDimensions = (layout: ImageDimensionsType): Dimensions => {
  const deviceType = getDeviceType();

  const sizeX = 440;
  const sizeY = 264;

  const logoSizeX = 100;
  const logoSizeY = 100;

  if (
    deviceType === 'desktop' ||
    deviceType === 'small-desktop' ||
    deviceType === 'laptop' ||
    deviceType === 'tablet-landscape'
  ) {
    if (layout === 'view-page') {
      return { width: percentIncrease(sizeX, 100), height: percentIncrease(sizeY, 100) };
    } else if (layout === 'logo') {
      return { width: percentIncrease(logoSizeX, 50), height: percentIncrease(logoSizeY, 50) };
    } else if (layout === 'logo-small') return { width: logoSizeX, height: logoSizeY };
    else if (layout === 'logo-normal') {
      return { width: percentIncrease(logoSizeX, 40), height: percentIncrease(logoSizeX, 40) };
    } else if (layout === 'logo-large') {
      return { width: percentIncrease(logoSizeX, 70), height: percentIncrease(logoSizeY, 70) };
    } else if (layout === 'no-crop-logo') return { width: percentIncrease(logoSizeY, 50) };
    else if (layout === 'no-crop-logo-small') return { width: logoSizeY };
    else if (layout === 'no-crop-logo-large') return { width: percentIncrease(logoSizeY, 150) };
    else if (layout === 'columns-1') {
      return { width: percentIncrease(sizeX, 80), height: percentIncrease(sizeY, 80) };
    } else if (layout === 'columns-2') {
      return { width: percentIncrease(sizeX, 50), height: percentIncrease(sizeY, 50) };
    } else if (layout === 'columns-3') return { width: sizeX, height: sizeY };
    else if (layout === 'columns-4') {
      return { width: percentDecrease(sizeX, 10), height: percentDecrease(sizeY, 10) };
    } else if (layout === 'columns-5') {
      return { width: percentDecrease(sizeX, 20), height: percentDecrease(sizeY, 20) };
    }
  } else if (deviceType === 'mobile') {
    if (layout === 'view-page') {
      return { width: percentDecrease(sizeX, 15), height: percentDecrease(sizeY, 15) };
    } else if (layout === 'logo') {
      return { width: percentIncrease(logoSizeX, 50), height: percentIncrease(logoSizeY, 50) };
    } else if (layout === 'logo-small') {
      return { width: percentDecrease(logoSizeX, 10), height: percentDecrease(logoSizeY, 10) };
    } else if (layout === 'logo-normal') {
      return { width: percentIncrease(logoSizeX, 10), height: percentIncrease(logoSizeX, 10) };
    } else if (layout === 'logo-large') {
      return { width: percentIncrease(logoSizeX, 70), height: percentIncrease(logoSizeY, 70) };
    } else if (layout === 'no-crop-logo') return { width: logoSizeY };
    else if (layout === 'no-crop-logo-small') return { width: percentDecrease(logoSizeY, 10) };
    else if (layout === 'no-crop-logo-large') return { width: percentIncrease(logoSizeY, 150) };
    else if (['columns-1', 'columns-2', 'columns-3', 'columns-4', 'columns-5'].includes(layout)) {
      return { width: sizeX, height: sizeY };
    }
  }

  return { width: sizeX, height: sizeY };
};

const validatePicture = (picture: string) => {
  const defaultPictures = [
    `${S3_IMAGE_BUCKET_URL}/user-active.jpg`,
    `${S3_IMAGE_BUCKET_URL}/top-tier-candidate.png`,
    `${S3_IMAGE_BUCKET_URL}/candidate.png`,
    `${S3_IMAGE_BUCKET_URL}/cohire-image-bucket-2/no-image-cover.png`
  ];

  for (const defaultPicture of defaultPictures) {
    if (picture && picture === defaultPicture) return false;
  }

  if (
    picture &&
    !new RegExp('https://randomuser.me').test(picture) &&
    !new RegExp('https://uifaces.co').test(picture)
  ) {
    return true;
  }
  return false;
};

/**
 *
 * @param {object} data - the data where the picture urls are
 * @param {object} params - single object or array of objects. each object having a 'type' and 'dimensions'
 * The 'dimensions' is the string returned by getImageDimensions. The 'type' is the layout type, view-page (pages such as CompanyProfile, PositionProfile, etc.),
 * logo (round logo images) or columns
 * columns takes values 1, 2, 3. A page where the pictures are spread in columns (pages such as the landing homepage, CompanyBlockList, etc..)
 * Leave layout parameter as null if you are passing columns
 * @description A function that iterates over an object, finds picture_url, logo or company_logo and appends image dimensions.
 */
export const addDimensionsOnImages = (
  data: $TSFixMe,
  params: ImageResizeParam | ImageResizeParam[]
) => {
  let outputData = structuredClone(data);
  if (params && Array.isArray(params) && !isEmpty(outputData)) {
    params.forEach(({ type, dimensions }) => {
      outputData = setImageDimensions_UNSAFE(outputData, type, dimensions);
    });
  } else if (isObject_Deprecated(params) && !isEmpty(outputData)) {
    outputData = setImageDimensions_UNSAFE(
      outputData,
      (params as ImageResizeParam).type,
      (params as ImageResizeParam).dimensions
    );
  }
  return outputData;
};

export const addDimensionsMessageThread = (data: ExtendedMessage[]) => {
  if (!data) return data;

  const dimensions = { width: 90 };
  data.forEach(({ senderDetails }) => {
    const url = structuredClone(senderDetails.photoURL);

    if (validatePicture(url)) senderDetails.photoURL = getResizedImageURL(url, dimensions);
  });

  return data;
};

const setImageDimensions_UNSAFE = (
  data: $TSFixMe,
  layoutType: LayoutType,
  dimensions: Dimensions
) => {
  if (!data) return data;

  if (layoutType === 'view-page') {
    const imageKeyType = getPictureType(data);
    if (imageKeyType && Array.isArray(data[imageKeyType])) {
      data[imageKeyType].forEach((url: string, index: number) => {
        if (validatePicture(url)) {
          data[imageKeyType][index] = getResizedImageURL(url, dimensions);
        }
      });
    } else if (imageKeyType) {
      if (validatePicture(data[imageKeyType])) {
        data[imageKeyType] = getResizedImageURL(data[imageKeyType], dimensions);
      }
    }
  } else if (layoutType === 'logo') {
    if (data && Array.isArray(data)) {
      data.forEach((item, index) => {
        data[index] = setLogoDimensions(item, dimensions);
      });
    } else {
      // eslint-disable-next-line no-param-reassign
      data = setLogoDimensions(data, dimensions);
    }
  } else if (layoutType === 'columns') {
    data.forEach((item: $TSFixMe) => {
      const imageKeyType = getPictureType(item);
      // @ts-expect-error TS(2538) FIXME: Type 'false' cannot be used as an index type.
      const imageURL = Array.isArray(item[imageKeyType])
        ? // @ts-expect-error TS(2538) FIXME: Type 'false' cannot be used as an index type.
          item[imageKeyType][0]
        : // @ts-expect-error TS(2538) FIXME: Type 'false' cannot be used as an index type.
          item[imageKeyType];

      if (imageKeyType && validatePicture(imageURL)) {
        if (Array.isArray(item[imageKeyType])) {
          item[imageKeyType][0] = getResizedImageURL(imageURL, dimensions);
        } else item[imageKeyType] = getResizedImageURL(imageURL, dimensions);
      }
    });
  }

  return data;
};

const setLogoDimensions = (item: $TSFixMe, dimensions: Dimensions) => {
  const logoType = [
    'photo_url',
    'photoURL',
    'photoUrl',
    'memberPhotoURL',
    'company_logo',
    'companyLogo',
    'logoURL',
    'storyLogo'
  ];
  const wrappers = ['associatedMemberDetails'];

  logoType.forEach(type => {
    const imageURL = item[type];
    if (validatePicture(imageURL) && imageURL) {
      item[type] = getResizedImageURL(imageURL, dimensions);
    }
  });

  wrappers.forEach(type => {
    const imageURL = item[type]?.photoURL || '';
    if (imageURL && validatePicture(imageURL)) {
      item[type].photoURL = getResizedImageURL(imageURL, dimensions);
    }
  });
  return item;
};

const getCDNUrl = (sharpAPIRequestBody: Record<string, any>) => {
  return `${IMAGE_CDN_URL}/${window.btoa(JSON.stringify(sharpAPIRequestBody))}`;
};

const getSharpAPIRequestBodyFromURL = (url: string) => {
  const encodedSharpAPIRequestString = url.replace('https://', '').split('/')[1];
  return JSON.parse(window.atob(encodedSharpAPIRequestString));
};

export const removeImageDimensions = (url = '') => {
  if (!url || !url.startsWith(IMAGE_CDN_URL)) return url;
  const sharpAPIRequestBody = getSharpAPIRequestBodyFromURL(url);

  delete sharpAPIRequestBody.edits;

  return getCDNUrl(sharpAPIRequestBody);
};

export const getImageDimensionsObj = (url = '') => {
  if (!url.startsWith(IMAGE_CDN_URL)) return { width: '', height: '' };

  const sharpAPIRequestBody = getSharpAPIRequestBodyFromURL(url);
  const { width = '', height = '' } = sharpAPIRequestBody?.edits?.resize || {};
  return { width, height };
};

export const getResizedImageURL = (
  url: string,
  dimensions: Dimensions,
  fit: ResizeMode = 'cover'
) => {
  if (!url) return '';
  // This is to avoid attempting to resize on static assets
  if (url.startsWith('/') || url.startsWith('data') || url.startsWith('https://assets')) return url;

  if (url.startsWith(IMAGE_CDN_URL)) {
    const sharpAPIRequestBody = getSharpAPIRequestBodyFromURL(url);

    const newSharpAPIRequestBody = {
      ...sharpAPIRequestBody,
      edits: {
        resize: {
          ...dimensions,
          fit: 'cover'
        }
      }
    };

    return getCDNUrl(newSharpAPIRequestBody);
  }

  const bucket = url.replace('https://', '').split('.')[0];
  const key = extractS3Key(url);

  const sharpAPIRequestBody = {
    bucket,
    key: encodeURI(key),
    edits: {
      resize: {
        ...dimensions,
        fit
      }
    }
  };

  return getCDNUrl(sharpAPIRequestBody);
};
