import React, { CSSProperties, useEffect, useMemo } from 'react';
import ClassNames from 'classnames';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { PricingCountry } from 'types';
import { checkIfESSUProgramPage, isSanFranLandingPage } from 'utils/url';
import { S3_IMAGE_BUCKET_URL } from 'consts';
import { useSearchParams } from 'hooks/useSearchParams';

import HubSpotCalendar from 'views/landing/requestdemo/hubspotcalendar';

import CustomerStorySliderSection from 'components/customerstoryslidersection';
import FAQSection from 'components/faqsection';
import PageSection from 'components/pagesection';
import Seo from 'components/seo';
import TwoColumnSection from 'components/twocolumnsection';

import Avatar from 'v2/components/ui/atoms/Avatar';
import ConditionalRender from 'v2/components/utility/ConditionalRender';

import { getLeftColumnProps, getQuote, getSEOContent } from './helpers';
import SpecialEnquiryForm from './SpecialEnquiryForm';
import { ESSUPageStyling } from './FounderProgramPage.styled';

export default function FounderProgramPage() {
  const location = useLocation<{ pricingType?: PricingCountry }>();
  const isESSUProgramPage = checkIfESSUProgramPage(location.pathname);
  const isSanFranPage = isSanFranLandingPage();
  const { searchParams } = useSearchParams();

  const { leftColumn, quote, seoContent } = useMemo(
    () => ({
      leftColumn: getLeftColumnProps(searchParams, isESSUProgramPage, isSanFranPage),
      quote: getQuote(isESSUProgramPage, isSanFranPage),
      seoContent: getSEOContent(isESSUProgramPage, isSanFranPage)
    }),
    [isESSUProgramPage, isSanFranPage]
  );

  useEffect(() => {
    document.body.classList.add('public');
  }, []);

  return (
    <>
      <ApplyForFounder
        className={ClassNames({ es_su_page: isESSUProgramPage, san_francisco_page: isSanFranPage })}
        leftColumn={leftColumn}
      >
        <ConditionalRender predicate={isESSUProgramPage} fallback={<SpecialEnquiryForm />}>
          <HubSpotCalendar />
        </ConditionalRender>
      </ApplyForFounder>
      <CustomerStorySliderSection showStoriesButton showCompanyLogos={false} />
      <QuoteSection
        title={quote.title}
        subTitle={quote.subTitle}
        className="center_alignment gradient_blue_background discount_program_quote"
        widthClassName="page_view"
        subtitleMaxWidth={isESSUProgramPage ? '650px' : '850px'}
      >
        <QuoteAuthor isSanFranPage={isSanFranPage} />
      </QuoteSection>
      <FAQSection
        showROIButtons={isSanFranPage ? 'single' : 'both'}
        pricingVer={isESSUProgramPage ? 5 : 4}
      />
      <Seo
        title={seoContent.title}
        description={seoContent.description}
        path={`${location.pathname}${location.search}`}
        contentType="website"
        image="https://assets.co-hire.com/make-direct-hires.png"
      />
    </>
  );
}

const QuoteAuthor = ({ isSanFranPage }: { isSanFranPage: boolean }) => {
  return (
    <ConditionalRender
      predicate={isSanFranPage}
      fallback={
        <>
          <Avatar photoURL="https://assets.co-hire.com/ben-quote-photo.jpg" width={40} />
          <strong>Ben, Co-founder & CEO at cord</strong>
        </>
      }
    >
      <Avatar
        photoURL={`${S3_IMAGE_BUCKET_URL}/tom-profile-picture.png`}
        resizePhotoDimensions={{ width: 40 }}
        width={40}
      />
      <strong>Tom Woods, Chief Product Officer</strong>
    </ConditionalRender>
  );
};

const ApplyForFounder = styled(TwoColumnSection)`
  justify-content: space-between;
  margin-bottom: 2rem;

  ${({ theme: { media } }) => media.tabletMin`
    .left_column,
    .right_column {
      flex-basis: 45%;
    }
  `}

  .company_logos_section {
    max-width: 526px;
    margin-top: 32px;
  }

  &.es_su_page {
    ${ESSUPageStyling}
  }

  &.san_francisco_page {
    ${({ theme: { media } }) => media.smallLaptopMin`  
    .page_section_title {
      font-size: 50px; 
      line-height: 60px;
      margin: 0 auto;
      padding-bottom: 0;
    }
  `}
  }
`;

const QuoteSection = styled(PageSection)<{ subtitleMaxWidth: CSSProperties['maxWidth'] }>`
  h3.page_section_subtitle {
    max-width: ${({ subtitleMaxWidth }) => subtitleMaxWidth};

    ${({ theme: { media } }) => media.mobileMin`
      font-size: 1.5rem;
    `}
  }

  strong {
    margin-left: 12px;
    font-size: 20px;
  }
`;
