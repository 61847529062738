/* eslint-disable no-return-await */
import React, { ComponentType, lazy, Suspense } from 'react';
import axios from 'axios';
import axiosCancel from 'axios-cancel';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import ClassNames from 'classnames';
import { SkeletonTheme } from 'react-loading-skeleton';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { TYPES_LIST } from 'prismic-configuration';
import * as createHistory from 'history';

import structuredClone from '@ungap/structured-clone';
import { datadogLogs } from '@datadog/browser-logs';
import { bindActionCreators } from '@reduxjs/toolkit';
import {
  AdminAccountInterface,
  CandidateSignupVerificationStatus as CSVS,
  FeedInterface,
  MessageFilter
} from '@cohiretech/common-types';

import {
  CompanyProfile as CompanyProfileType,
  CandidateProfile as CandidateProfileType,
  CompanyOnboardingItem,
  Permissions,
  CandidateNotificationResult
} from 'types';
import { setLocalStorageItem } from 'cookieManager';
import {
  getCandidateNotificationsCount,
  setNotificationsOnTab,
  storeReferrerTrackingDetails
} from 'utils';
import { trackPageView } from 'tracking-utils';
import { BubbleName, getDemoCompanyTour } from 'v2/services/company/tour';
import * as fetcher from 'fetcher';
import { isTabletLandscapeWidth } from 'responsiveConfig';
import { retryImport } from 'utils/fn/retry';
import {
  fetchAdminData,
  fetchCandidateData,
  fetchCompanyData,
  reloadCompanyNotifications,
  reloadCompanyStreams,
  reloadCompanyShortlists,
  updateLoginStatus,
  updateMemberProfile
} from 'store/services';
import {
  AppDispatch,
  ProfileState,
  RootState,
  selectDarkMode,
  initTheme,
  isCandidateProfile,
  isCompanyProfile,
  selectCandidateProfile,
  selectCompanyProfile,
  selectProfile,
  setProfile,
  CandidateTour,
  toggleReactivationModal,
  persistCandidateTourBubbleShown,
  removeCandidateTourBubble,
  setIsProfileUpdated,
  setMessageFilter,
  setPreferences,
  persistCompanyOnboardingItemCompleted,
  setCandidatesToVerify,
  decrementCompaniesToVerify,
  setPositionSearchVisibility,
  setPositionStatus,
  updateMemberPosition,
  setCompanyStreams,
  setCompanyResponseRate,
  setCompanyResponseTime,
  setUserResponseRate,
  setUserResponseTime,
  decrementHiringSeats,
  incrementHiringSeats,
  setShouldPromptStatus,
  toggleShowOfflineNotification,
  setAdminAccount,
  toggleStartUsingCordPopup,
  toggleSessionEndedPopup,
  setPermissions,
  selectLoader,
  selectCompaniesToVerify,
  selectCandidatesToVerify,
  selectPermissions,
  selectInitialSetupMode,
  selectIsProfileUpdated,
  selectSetupMode,
  selectShowReactivationModal,
  selectCandidateTour,
  selectDemoCompanyTour,
  selectShowCandidateTour,
  selectDemoCompany,
  selectIntegrationRedirectURL,
  selectShowOfflineNotification,
  selectShowStartUsingCordPopup,
  selectSessionEndedPopup,
  selectUserType,
  selectAdminAccount,
  setDemoCompanyTour,
  setCandidateNotifications,
  setTotalNotifications,
  setInitialSetupMode,
  setSetupMode,
  setReceivedRequestsPending,
  selectToggler,
  onDemoTourBubbleShown,
  DemoCompanyTourPage,
  initLocalisation
} from 'store';
import {
  selectNavigatablePositionsIDs,
  selectShouldShowNavigatablePositions
} from 'store/navigatablePositions';
import { isLocalDevelopment, isStageDevelopment, RouteMonitor } from 'v2/services/app';
import CordTheme from 'styles/theme/cordTheme';

import { pluraliseInsightsType } from 'views/landing/insights/helpers';
import About from 'views/landing/about';
import ConversationCode from 'views/landing/conversationcode';
import Contacts from 'views/landing/contact';
import CreatePassword from 'views/landing/createpassword';
import CustomerStories from 'views/landing/customerstories';
import CustomerStory from 'views/landing/customerstories/customerstory';
import Homepage from 'views/landing/homepage';
import Invite from 'views/landing/invite';
import JoinTeam from 'views/landing/jointeam';
import Login from 'views/landing/login';
import PasswordReset from 'views/landing/passwordreset';
import PersonProfile from 'views/personprofile';
import PositionProfileView from 'views/positionprofile';
import PublicRoute from 'views/landing';
import RequestDemo from 'views/landing/requestdemo';
import FounderProgram from 'views/landing/founderprogram';
import ForCompanies from 'views/landing/forcompanies';
import ForAgencies from 'views/landing/foragencies';
import Testimonials from 'views/landing/testimonials';
import Pricing from 'views/landing/pricing';
import Checkout from 'views/landing/checkout';
import PMFSurvey from 'views/landing/pmfsurvey';
import CompanyInvitePage from 'views/landing/companyinvite';
import HiresPage from 'views/landing/hires';
import AdminRoute from 'views/admin';
import NotFound from 'views/notfound';

import AwardsPage from 'v2/pages/public/Awards';
import ApplicationTrackerPosition from 'v2/pages/candidate/ApplicationTrackerPosition';

import Footer from 'components/footer';
import ErrorBoundary from 'components/errorboundary';
import Loader from 'components/loader';
import ScrollToTop from 'components/scrolltotop';
import TopNav from 'components/topnav';
import NotificationBar from 'components/notificationbar';
import { SHARE_URL_TYPE } from 'components/companysidebar/pagetitle/index';
import StartUsingCordPopup from 'components/startusingcordpopup';
import DemoCreateAccountProfile from 'components/democreateaccountprofile';

import CVDownloadPrompt from 'v2/components/CVDownloadPrompt';
import ConditionalRender from 'v2/components/utility/ConditionalRender';
import { isFeatureEnabled } from 'v2/components/utility/FeatureToggle';
import DevTools from 'v2/components/utility/DevTools';
import { PublicEmbedScripts } from 'v2/components/utility/EmbedScripts';

import colours from 'css/base/_colours.module.scss';

import 'css/app.scss';

const CompanyRoute = lazy(() => retryImport(() => import('views/company')));
const CompanyProfileView = lazy(() => retryImport(() => import('views/company/profile')));
const CompanySettings = lazy(() => retryImport(() => import('views/company/settings')));
const CompanyTemplates = lazy(() => retryImport(() => import('views/company/templates')));
const CompanyUsers = lazy(() => retryImport(() => import('views/company/users')));
const CompanyHelp = lazy(() => retryImport(() => import('views/company/help')));
const Referrals = lazy(() => retryImport(() => import('v2/pages/company/Referrals')));
const CompanySearch = lazy(() => retryImport(() => import('v2/pages/company/Discover/Search')));
const CompanyStreams = lazy(() => retryImport(() => import('v2/pages/company/Discover/Streams')));
const CompanyMessages = lazy(() => retryImport(() => import('views/company/messages')));
const CompanyMessagesV2 = lazy(() => retryImport(() => import('v2/pages/company/Messages')));
const CompanyPositionProfileView = lazy(() =>
  retryImport(() => import('views/company/position/profile'))
);
const CompanyLists = lazy(() => retryImport(() => import('views/company/saved')));
const CompanyUserProfile = lazy(() => retryImport(() => import('views/company/userprofile')));
const CompanyEditUserProfile = lazy(() =>
  retryImport(() => import('views/company/userprofile/edit'))
);
const CompanyCalendarEvents = lazy(() => retryImport(() => import('views/company/calendarevents')));
const CompanyCalendarEventEdit = lazy(() =>
  retryImport(() => import('views/company/calendarevents/edit'))
);
const CompanySetupPositions = lazy(() => retryImport(() => import('views/company/setuppositions')));
const CompanySetupFinish = lazy(() => retryImport(() => import('views/company/setupfinish')));
const CompanySetupAccount = lazy(() => retryImport(() => import('views/company/setupaccount')));
const CompanyProfile = lazy(() => retryImport(() => import('views/companyprofile')));

const Signup = lazy(() => retryImport(() => import('views/signup')));
const Slack = lazy(() => retryImport(() => import('views/landing/slack')));
const Sitemap = lazy(() => retryImport(() => import('views/landing/sitemap')));
const ForgotPassword = lazy(() => retryImport(() => import('views/landing/forgotpassword')));
const Brand = lazy(() => retryImport(() => import('views/landing/brand')));
const Team = lazy(() => retryImport(() => import('views/landing/team')));
const Careers = lazy(() => retryImport(() => import('views/landing/careers')));
const Partners = lazy(() => retryImport(() => import('views/landing/partners')));
const Partnership = lazy(() => retryImport(() => import('views/landing/partners/partnershippage')));
const Unsubscribe = lazy(() => retryImport(() => import('views/landing/unsubscribe')));
const Search = lazy(() => retryImport(() => import('views/search')));
const InsightsHomepage = lazy(() => retryImport(() => import('views/landing/insights/homepage')));
const InsightsContent = lazy(() => retryImport(() => import('views/landing/insights/content')));
const InsightsTopicsPage = lazy(() =>
  retryImport(() => import('views/landing/insights/topicpage'))
);
const InsightsTypePage = lazy(() => retryImport(() => import('views/landing/insights/typepage')));
const SalaryTool = lazy(() => retryImport(() => import('views/salarytool')));
const SalaryToolLandingPage = lazy(() => retryImport(() => import('views/landing/salarytool')));
const SalaryToolPartnershipLandingPage = lazy(() =>
  retryImport(() => import('views/landing/salarytoolpartnership'))
);

const CompanyPositions = lazy(() => retryImport(() => import('v2/pages/company/Positions')));
const CompanyShortlists = lazy(() =>
  retryImport(() => import('v2/pages/company/Discover/Shortlists'))
);
const CompanyAnalytics = lazy(() => retryImport(() => import('views/company/analytics')));
const CompanyEditProfile = lazy(() => retryImport(() => import('views/company/profile/edit')));
const CompanyPositionEdit = lazy(() => retryImport(() => import('views/company/position/edit')));
const CompanyCreatePosition = lazy(() =>
  retryImport(() => import('views/company/position/create'))
);
const CompanyLiveLeads = lazy(() => retryImport(() => import('v2/pages/company/LiveLeads')));
const CandidateRoute = lazy(() => retryImport(() => import('views/candidate')));
const CandidateProfile = lazy(() => retryImport(() => import('views/candidateprofile')));
const CandidateDiscover = lazy(() => retryImport(() => import('views/candidate/discover')));
const CandidateSearch = lazy(() => retryImport(() => import('views/candidate/search')));
const CandidateSettings = lazy(() => retryImport(() => import('views/candidate/settings')));
const CandidateMessages = lazy(() => retryImport(() => import('views/candidate/messages')));
const CandidateHelp = lazy(() => retryImport(() => import('views/candidate/help')));
const CandidateTemplates = lazy(() => retryImport(() => import('views/candidate/templates')));
const CandidateInvites = lazy(() => retryImport(() => import('views/candidate/invites')));
const CandidateAccount = lazy(() => retryImport(() => import('views/candidate/account')));
const NavigatablePositions = lazy(() =>
  retryImport(() => import('v2/pages/candidate/NavigatablePositions'))
);
const ApplicationTracker = lazy(() =>
  retryImport(() => import('v2/pages/candidate/ApplicationTracker'))
);

const CVBuilder = lazy(() => retryImport(() => import('v2/components/CVCompiler')));

const AdminLogin = lazy(() => retryImport(() => import('views/landing/adminlogin')));
const AdminUsers = lazy(() => retryImport(() => import('views/admin/users')));
const AdminAccountView = lazy(() => retryImport(() => import('views/admin/account')));
const AdminProductTools = lazy(() => retryImport(() => import('views/admin/product-tools')));
const AdminDashboard = lazy(() => retryImport(() => import('views/admin/dashboard')));
const AdminTechTools = lazy(() => retryImport(() => import('views/admin/tech-tools')));
const AdminSQlEditor = lazy(() => retryImport(() => import('views/admin/sql-editor/queries')));
const AdminCreateSQLWriteQuery = lazy(() =>
  retryImport(() => import('views/admin/sql-editor/create'))
);
const AdminCandidateTools = lazy(() => retryImport(() => import('views/admin/candidates/tools')));
const AdminCandidatesSignup = lazy(() =>
  retryImport(() => import('v2/pages/admin/candidate/Signup'))
);
const AdminCandidateSearch = lazy(() => retryImport(() => import('views/admin/candidates/search')));
const AdminCompanyVerify = lazy(() =>
  retryImport(() => import('views/admin/companies/verifyfeed'))
);
const AdminCompanySearch = lazy(() => retryImport(() => import('views/admin/companies/search')));
const AdminCompanyTools = lazy(() => retryImport(() => import('views/admin/companies/tools')));
const AdminCreditSystem = lazy(() =>
  retryImport(() => import('views/admin/companies/credit-system'))
);
const AdminCompanyPartnerships = lazy(() =>
  retryImport(() => import('views/admin/companies/partners'))
);
const AdminCompanyCreatePartnership = lazy(() =>
  retryImport(() => import('views/admin/companies/partners/create'))
);
const AdminCompanyCheckout = lazy(() =>
  retryImport(() => import('views/admin/companies/checkout-pages'))
);
const AdminCompanyCreateCheckout = lazy(() =>
  retryImport(() => import('views/admin/companies/checkout-pages/create'))
);
const AdminCompanyStories = lazy(() =>
  retryImport(() => import('views/admin/customersuccess/stories'))
);
const AdminCreateCompanyStory = lazy(() =>
  retryImport(() => import('views/admin/customersuccess/stories/create'))
);
const AdminCSDashboard = lazy(() =>
  retryImport(() => import('views/admin/customersuccess/dashboard'))
);
const AdminCSTools = lazy(() => retryImport(() => import('views/admin/customersuccess/tools')));
const AdminTagging = lazy(() => retryImport(() => import('views/admin/tagging-system')));
const AdminWheel = lazy(() => retryImport(() => import('views/admin/shoutout-wheel')));
const AdminCreditRefund = lazy(() =>
  retryImport(() => import('v2/pages/admin/CreditRefundToolPage'))
);
const AdminExternalPositions = lazy(() =>
  retryImport(() => import('views/admin/external-position'))
);

const history = createHistory.createBrowserHistory();

// @ts-expect-error: Todo: research better way to do this
axiosCancel(axios, { debug: false });

class BrowserRoute extends Route<any, any> {
  componentDidMount() {
    if (this.props && this.props.location) {
      const location = this.props.location;
      storeReferrerTrackingDetails(location.search);
      trackPageView(location);
    }
  }

  componentDidUpdate(prevProps: any) {
    const { location: prevLocation } = prevProps;
    const { location } = this.props;

    if (location && prevLocation && location.pathname !== prevLocation.pathname) {
      trackPageView(location);
    }
  }
}

export interface AppState {
  changedPositions: number[];
  changedCandidates: number[];
}

export interface TransitionState {
  // loading: boolean;
  profile: ProfileState;
  admin: AdminAccountInterface | null;
  permissions: Permissions;
  companiesToVerify: number;
  candidatesToVerify: number;
  userType: string;
  integrationRedirectURL: string;
  demoCompany: boolean;
  candidateTour: CandidateTour;
  showCandidateTour: boolean;
  demoCompanyTour: { name: string; page: { hash: string; pathname: string }; shown: boolean }[];
  showReactivationModal: boolean;
  setupMode: boolean;
  initialSetupMode: boolean;
  showOfflineNotification: boolean;
  showStartUsingCordPopup: boolean;
  sessionEndedPopup: boolean;
  isProfileUpdated: boolean;
  onLoginStatusChange?: () => Promise<void>;
  onBubbleShown?: (bubbleName: string) => void;
  onDemoTourBubbleShown?: (
    bubbleName: BubbleName,
    nextBubbleLocation?: DemoCompanyTourPage
  ) => Promise<void>;
  updateDemoCompanyTour?: () => void;
  reloadCandidateNotifications?: (returnNotifications: boolean) => Promise<void>;
  reloadCompanyNotifications?: (returnNotifications?: boolean) => Promise<any>;
  toggleReactivationModal?: (show?: boolean, ctaDetails?: { source: string }) => Promise<void>;
  toggleStartUsingCordPopup?: (show: boolean, ctaDetails?: { source: string }) => void;
  showSessionEndedPopup?: () => void;
  updateMemberProfile?: () => Promise<void>;
  updateCompanyResponseRate?: (responseData: any) => void;
  updateCompanyResponseTime?: (responseData: any) => void;
  updateUserResponseTime?: (responseData: any) => void;
  updateUserResponseRate?: (responseData: any) => void;
  setProfileUpdated?: (isProfileUpdated: boolean) => void;
  axios?: any;

  // last parts of old state
  changedPositions: number[];
  changedCandidates: number[];
}

type AppProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);
    this.state = {
      changedPositions: [],
      changedCandidates: []
    };

    this.toggleOfflineNotification = this.toggleOfflineNotification.bind(this);
    this.getUserID = this.getUserID.bind(this);
  }

  async onDemoTourBubbleShown(bubbleName: BubbleName, nextBubbleLocation?: DemoCompanyTourPage) {
    this.props.onDemoTourBubbleShown({ bubbleName, nextBubbleLocation });
  }

  // Todo: check this logic
  updateDemoCompanyTour() {
    const { profile, demoCompany } = this.props;

    if (demoCompany || (profile as CompanyProfileType)?.isUnpaidAccount) {
      this.props.setDemoCompanyTour(getDemoCompanyTour(this.props.demoCompanyTour));
    }
  }

  // Todo: make async thunk / service
  async reloadCandidateNotifications(returnNotifications = false) {
    const location = window.location;
    const profile = structuredClone(this.props.profile);
    if (!isCandidateProfile(profile)) return;

    const notifications = await fetcher.getCandidateNotifications();
    const totalNotifications = getCandidateNotificationsCount(notifications);

    setNotificationsOnTab(totalNotifications, location.pathname);

    profile.notifications = notifications;
    profile.totalNotifications = totalNotifications;

    this.props.setCandidateNotifications(notifications);
    this.props.setTotalNotifications(totalNotifications);

    if (returnNotifications) return notifications;
  }

  onPositionChange(positionID: number) {
    this.setState(({ changedPositions }) => ({
      changedPositions: changedPositions.concat(positionID)
    }));
  }

  onCandidateChange(candidateID: number) {
    this.setState(({ changedCandidates }) => ({
      changedCandidates: changedCandidates.concat(candidateID)
    }));
  }

  async recordPWAInstalledEvent() {
    await fetcher.recordPWAEvent('PWA_INSTALLED', { userID: this.getUserID() });
  }

  async recordPWABannerShownEvent() {
    await fetcher.recordPWAEvent('PWA_BANNER_SHOWN', { userID: this.getUserID() });
  }

  async recordPWAOpened(newProfile?: CompanyProfileType | CandidateProfileType) {
    await fetcher.recordPWAEvent('PWA_OPENED', { userID: this.getUserID(newProfile) });
  }

  addPWAEventListeners() {
    window.addEventListener('offline', this.toggleOfflineNotification);
    window.addEventListener('online', this.toggleOfflineNotification);
    window.addEventListener('appinstalled', this.recordPWAInstalledEvent);
    window.addEventListener('onbeforeinstallprompt', this.recordPWABannerShownEvent);
  }

  removePWAEventListeners() {
    window.removeEventListener('offline', this.toggleOfflineNotification);
    window.removeEventListener('online', this.toggleOfflineNotification);
    window.removeEventListener('onappinstalled', this.recordPWAInstalledEvent);
    window.removeEventListener('onbeforeinstallprompt', this.recordPWABannerShownEvent);
  }

  componentDidMount() {
    this.onLoginStatusChange();
    this.addPWAEventListeners();
  }

  componentWillUnmount() {
    this.removePWAEventListeners();
  }

  /** redux implemented in methods below this line */

  async toggleReactivationModal(show?: boolean, ctaDetails = { source: '' }) {
    await this.props.toggleReactivationModal({ show, ctaDetails });
  }

  onBubbleShown(bubbleName: string) {
    this.props.persistCandidateTourBubbleShown({
      bubbleName
    });
  }

  removeCandidateTourBubble(bubbleName: string) {
    this.props.removeCandidateTourBubble(bubbleName);
  }

  setProfileUpdated(isProfileUpdated: boolean) {
    this.props.setIsProfileUpdated(isProfileUpdated);
  }

  updateMessageFilter(filter: MessageFilter) {
    this.props.setMessageFilter(filter);
  }

  updateProfileState(key: string, value: any) {
    // Currently only used in two places, so it's easy to replace. If else for now.
    if (key === 'preferences') {
      this.props.setPreferences(value);
    } else if (key === 'messageFilter') {
      this.updateMessageFilter(value);
    }
  }

  async updateMemberProfile() {
    await this.props.updateMemberProfile();
  }

  async reloadCompanyShortlists() {
    await this.props.reloadCompanyShortlists();
  }

  async reloadCompanyStreams() {
    await this.props.reloadCompanyStreams();
  }

  onCompanyOnboardingItemCompletion(onboardingItem: CompanyOnboardingItem) {
    this.props.persistCompanyOnboardingItemCompleted(onboardingItem);
  }

  async reloadCompanyNotifications(returnNotifications = false) {
    const results = await this.props.reloadCompanyNotifications();
    if (!returnNotifications) return;

    // @ts-expect-error: typing for meta and payload doesn't come through automatically
    if (results?.meta.requestStatus === 'fulfilled') {
      // @ts-expect-error: typing for meta and payload doesn't come through automatically
      return results.payload?.notifications;
    }

    return [];
  }

  onCompanyVerified() {
    this.props.decrementCompaniesToVerify();
  }

  onCandidateVerified(count: number) {
    this.props.setCandidatesToVerify(count);
  }

  onPositionPause(positionID: number, resume: boolean) {
    this.props.setPositionStatus(positionID, resume);
  }

  onPositionVisible(positionID: number) {
    this.props.setPositionSearchVisibility(positionID, false);
  }

  onMemberPositionsUpdate(positionID: number) {
    this.props.updateMemberPosition(positionID);
  }

  onStreamsOrderUpdate(streams: FeedInterface[]) {
    this.props.setCompanyStreams(streams);
  }

  updateCompanyResponseRate(responseData: any) {
    const { responseRate } = responseData;
    this.props.setCompanyResponseRate(responseRate);
  }

  updateCompanyResponseTime(responseData: any) {
    const { responseTime } = responseData;
    this.props.setCompanyResponseTime(responseTime);
  }

  updateUserResponseRate(responseData: any) {
    const { responseRate, pendingCount } = responseData;
    this.props.setUserResponseRate(responseRate);
    this.props.setReceivedRequestsPending(pendingCount);
  }

  updateUserResponseTime(responseData: any) {
    const { responseTime } = responseData;
    this.props.setUserResponseTime(responseTime);
  }

  updateHiringSeats(action: 'add' | 'remove') {
    if (action === 'add') this.props.incrementHiringSeats();
    else if (action === 'remove') this.props.decrementHiringSeats();
  }

  hideWelcomeBackPrompt() {
    this.props.setShouldPromptStatus(false);
  }

  async onLoginStatusChange() {
    this.props.initTheme();
    this.props.initLocalisation();
    await this.props.updateLoginStatus(history);
  }

  toggleOfflineNotification() {
    this.props.toggleShowOfflineNotification();
  }

  setUser(user: { name: string; email?: string; id?: number }) {
    setLocalStorageItem('user', user);
    datadogLogs.addLoggerGlobalContext('user', user);
  }

  async getCompanyData(initialLoad = false) {
    await this.props.fetchCompanyData(initialLoad);
  }

  async getCandidateData() {
    await this.props.fetchCandidateData();
  }

  async getAdminData() {
    await this.props.fetchAdminData();
  }

  getUserID(newProfile?: CompanyProfileType | CandidateProfileType) {
    const profile = newProfile || this.props.profile;
    if (!profile) return null;
    return isCompanyProfile(profile) ? profile.companyUser.accountID : profile.id;
  }

  async toggleStartUsingCordPopup(
    show: boolean,
    ctaDetails: { source: string } | undefined = { source: '' }
  ) {
    this.props.toggleStartUsingCordPopup({ show, ctaDetails });
  }

  showSessionEndedPopup() {
    this.props.toggleSessionEndedPopup(true);

    setTimeout(async () => {
      await fetcher.logout();
    }, 20000);
  }

  updatePermissions(permissions: Permissions) {
    this.props.setPermissions(permissions);
  }

  render() {
    const {
      profile,
      darkMode,
      loading,
      admin,
      permissions,
      companiesToVerify,
      candidatesToVerify,
      userType,
      demoCompany,
      candidateTour,
      showCandidateTour,
      demoCompanyTour,
      showReactivationModal,
      setupMode,
      initialSetupMode,
      showOfflineNotification,
      isProfileUpdated,
      candidateProfile,
      companyProfile,
      integrationRedirectURL,
      sessionEndedPopup,
      showStartUsingCordPopup,
      insightsTrialBanner,
      shouldShowNavigatablePositions
    } = this.props;

    // @ts-ignore: Todo: fix this
    const { verified, rejected } = profile || {};

    // Todo: generalise for all top bars
    const topBanner = insightsTrialBanner;

    const transitionState: TransitionState = {
      profile,
      admin,
      permissions,
      companiesToVerify,
      candidatesToVerify,
      userType,
      demoCompany,
      candidateTour,
      showCandidateTour,
      demoCompanyTour,
      showReactivationModal,
      setupMode,
      initialSetupMode,
      showOfflineNotification,
      isProfileUpdated,
      integrationRedirectURL,
      sessionEndedPopup,
      showStartUsingCordPopup,

      // last parts of old state
      changedPositions: this.state.changedPositions,
      changedCandidates: this.state.changedCandidates,
      // ---

      onLoginStatusChange: async () => await this.onLoginStatusChange(),
      onBubbleShown: bubbleName => this.onBubbleShown(bubbleName),
      onDemoTourBubbleShown: (bubbleName, nextBubbleLocation) =>
        this.onDemoTourBubbleShown(bubbleName, nextBubbleLocation),
      updateDemoCompanyTour: () => this.updateDemoCompanyTour(),
      reloadCandidateNotifications: returnNotifications =>
        this.reloadCandidateNotifications(returnNotifications),
      reloadCompanyNotifications: returnNotifications =>
        this.reloadCompanyNotifications(returnNotifications),
      toggleReactivationModal: (show, ctaDetails) => this.toggleReactivationModal(show, ctaDetails),
      toggleStartUsingCordPopup: (show, ctaDetails) =>
        this.toggleStartUsingCordPopup(show, ctaDetails),
      showSessionEndedPopup: () => this.showSessionEndedPopup(),
      updateMemberProfile: () => this.updateMemberProfile(),
      updateCompanyResponseRate: responseData => this.updateCompanyResponseRate(responseData),
      updateCompanyResponseTime: responseData => this.updateCompanyResponseTime(responseData),
      updateUserResponseTime: responseData => this.updateUserResponseTime(responseData),
      updateUserResponseRate: responseData => this.updateUserResponseRate(responseData),
      setProfileUpdated: isProfileUpdated => this.setProfileUpdated(isProfileUpdated),
      axios
    };

    if (loading) {
      return <Loader className={ClassNames('large', { dark: darkMode })} />;
    } else {
      return (
        <CordTheme>
          <Router history={history}>
            <ScrollToTop />
            <SkeletonTheme
              color={darkMode ? colours.fontColourDarkShade : colours.lightBgColour}
              highlightColor={darkMode ? colours.darkerBgColour : colours.lightFontColour}
            >
              <div id="app">
                <RouteMonitor />
                <Helmet defer={false} titleTemplate={`%s - ${fetcher.SITENAME}`} />
                <PublicEmbedScripts />
                <Suspense fallback=" ">
                  <BrowserRoute
                    path="*"
                    render={routeProps => <Signup {...routeProps} {...transitionState} />}
                  />
                  <BrowserRoute
                    path="*/u/:id-:name"
                    exact
                    render={routeProps => (
                      <CompanyProfile darkMode={darkMode} {...routeProps} {...transitionState} />
                    )}
                  />
                  <BrowserRoute
                    path="*/u/:company/jobs/:id-:position"
                    exact
                    render={routeProps =>
                      shouldShowNavigatablePositions ? (
                        <NavigatablePositions
                          {...transitionState}
                          {...routeProps}
                          candidateProfile={candidateProfile}
                          onPositionChange={(id: number) => this.onPositionChange(id)}
                        />
                      ) : (
                        <PositionProfileView
                          {...transitionState}
                          {...routeProps}
                          candidateProfile={candidateProfile}
                          onPositionChange={(id: number) => this.onPositionChange(id)}
                        />
                      )
                    }
                  />
                  <BrowserRoute
                    path="*/u/candidate/:id"
                    exact
                    render={routeProps => (
                      <CandidateProfile
                        {...routeProps}
                        admin={admin}
                        permissions={permissions}
                        darkMode={darkMode}
                        profile={profile}
                        demoCompany={demoCompany}
                        companyProfile={companyProfile}
                        onCandidateChange={(id: number) => this.onCandidateChange(id)}
                        onCompanyOnboardingItemCompletion={f =>
                          this.onCompanyOnboardingItemCompletion(f)
                        }
                        reloadCompanyShortlists={() => this.reloadCompanyShortlists()}
                      />
                    )}
                  />
                  <BrowserRoute
                    path="*/u/:company/people/:id-:person"
                    exact
                    render={routeProps => (
                      <PersonProfile darkMode={darkMode} {...routeProps} {...transitionState} />
                    )}
                  />
                  <BrowserRoute
                    path="*/u/c/:company/jobs/:id-:position"
                    exact
                    component={ApplicationTrackerPosition}
                  />
                </Suspense>
                <div className="app_wrapper" id="body_wrapper">
                  <div
                    className={ClassNames('app_container', {
                      candidate: isCandidateProfile(profile),
                      company: isCompanyProfile(profile),
                      admin: !!admin,
                      setup_mode: setupMode,
                      initial_setup_mode: initialSetupMode
                    })}
                  >
                    <Route
                      path="*"
                      render={routeProps => (
                        // @ts-expect-error: TopNav prop typing needs a major refactor
                        <TopNav
                          onStreamsOrderUpdate={streams => this.onStreamsOrderUpdate(streams)}
                          reloadCompanyStreams={() => this.reloadCompanyStreams()}
                          onPositionPause={(positionID, resume) =>
                            this.onPositionPause(positionID, resume)
                          }
                          onPositionVisible={positionID => this.onPositionVisible(positionID)}
                          getCandidateData={() => this.getCandidateData()}
                          getCompanyData={() => this.getCompanyData()}
                          onMemberPositionsUpdate={id => this.onMemberPositionsUpdate(id)}
                          darkMode={darkMode}
                          {...routeProps}
                          {...transitionState}
                        />
                      )}
                    />
                    <ConditionalRender predicate={isLocalDevelopment() || isStageDevelopment()}>
                      <DevTools />
                    </ConditionalRender>
                    {(showStartUsingCordPopup || sessionEndedPopup) && (
                      <StartUsingCordPopup
                        title={sessionEndedPopup ? 'Your cord tour has ended' : ''}
                      />
                    )}
                    <main
                      data-testid="app_content"
                      className={ClassNames('app_content', { top_banner: topBanner })}
                      aria-label="contentinfo"
                    >
                      <ErrorBoundary>
                        <CVDownloadPrompt
                          isProfileUpdated={isProfileUpdated}
                          setProfileUpdated={transitionState.setProfileUpdated!}
                          isVerified={!!verified && !rejected}
                        />
                        <Suspense
                          fallback={
                            <Loader
                              className={ClassNames('large', 'page_loader', {
                                dark: darkMode
                              })}
                            />
                          }
                        >
                          <Switch>
                            {/* REDIRECTS */}
                            <BrowserRoute // WINTER 2023 Article - Remove this when adding Winter 2024 awards
                              path="/hiring-insights/hiring-leaders-awards/articles/winter-2024-hiring-leaders-awards"
                              render={() => (
                                <Redirect to="/hiring-insights/hiring-leaders-awards/articles/winter-2023-hiring-leaders-awards" />
                              )}
                            />
                            <BrowserRoute
                              path="/developer"
                              render={() => <Redirect to="/people" />}
                            />
                            <BrowserRoute
                              path="/platform/company"
                              render={() => <Redirect to="/login" />}
                            />
                            <BrowserRoute
                              path="/platform/candidate"
                              render={() => <Redirect to="/login" />}
                            />
                            <BrowserRoute
                              path="/auth/candidate/linkedin"
                              render={() => <Redirect to="/" />}
                            />
                            <BrowserRoute
                              path="/categories/industry-healthtech-company"
                              render={() => <Redirect to="/categories/healthtech-companies" />}
                            />
                            <BrowserRoute
                              path="/categories/covidResponder"
                              render={() => <Redirect to="/categories/covid-19-companies" />}
                            />
                            <BrowserRoute
                              path="/categories/lastActive"
                              render={() => <Redirect to="/categories/last-active-jobs" />}
                            />
                            <BrowserRoute
                              path="/categories/startupLife"
                              render={() => <Redirect to="/categories/startup-life" />}
                            />
                            <BrowserRoute
                              path="/the-life"
                              render={() => <Redirect to="/careers" />}
                            />
                            <BrowserRoute
                              path="/start-up"
                              render={() => <Redirect to="/companies" />}
                            />
                            <BrowserRoute
                              path="/enterprise"
                              render={() => <Redirect to="/companies" />}
                            />
                            <BrowserRoute
                              path="/partners/codeyourfuture"
                              render={() => <Redirect to="/partners/2-codeyourfuture" />}
                            />
                            <BrowserRoute
                              path="/partners/workable"
                              render={() => <Redirect to="/partners/1-workable" />}
                            />
                            <BrowserRoute
                              path="/search/m/jobs-in-london/:keyword"
                              render={routeProps => (
                                <Redirect
                                  to={`/search/m/jobs/${routeProps.match.params.keyword}`}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/search/jobs-in-london/:keyword"
                              render={routeProps => (
                                <Redirect to={`/search/jobs/${routeProps.match.params.keyword}`} />
                              )}
                            />
                            <BrowserRoute
                              path="/tea"
                              render={() => (
                                <Redirect to="/invited?invitation_code=developertea&utm_medium=developertea" />
                              )}
                            />
                            <BrowserRoute
                              path="/people/stories"
                              exact
                              render={() => <Redirect to="/testimonials/people" />}
                            />

                            {/* INSIGHTS REDIRECTS */}
                            <BrowserRoute
                              path="/techhub/finding-work/:uid"
                              exact
                              render={({ match, ...rest }) => {
                                if (
                                  TYPES_LIST.find(
                                    ({ value }) => pluraliseInsightsType(value) === match.params.uid
                                  )
                                ) {
                                  return (
                                    <InsightsTypePage
                                      match={{
                                        params: { topic: 'finding-work', type: match.params.uid }
                                      }}
                                      {...rest}
                                      {...transitionState}
                                    />
                                  );
                                }
                                return (
                                  <Redirect
                                    to={`/techhub/finding-work/stories/${match.params.uid}`}
                                  />
                                );
                              }}
                            />
                            <BrowserRoute
                              path="/techhub/:topic/:uid"
                              exact
                              render={({ match, ...rest }) => {
                                if (
                                  TYPES_LIST.find(
                                    ({ value }) => pluraliseInsightsType(value) === match.params.uid
                                  )
                                ) {
                                  return (
                                    <InsightsTypePage
                                      match={{
                                        params: {
                                          topic: match.params.topic,
                                          type: match.params.uid
                                        }
                                      }}
                                      {...rest}
                                      {...transitionState}
                                    />
                                  );
                                }
                                return (
                                  <Redirect
                                    to={`/techhub/${match.params.topic}/articles/${match.params.uid}`}
                                  />
                                );
                              }}
                            />

                            <BrowserRoute
                              path="/company/position/:id/track"
                              render={() => <Redirect to="/company/messages" />}
                            />
                            <BrowserRoute
                              path="/company/position/:id/sent"
                              render={() => <Redirect to="/company/messages" />}
                            />
                            <BrowserRoute
                              path="/company/position/:id/received"
                              render={() => <Redirect to="/company/messages" />}
                            />
                            <BrowserRoute
                              path="/company/position/:id/matches"
                              render={() => <Redirect to="/company/position/:id/view" />}
                            />
                            <BrowserRoute
                              path="/company/search"
                              render={() => <Redirect to="/company/discover" />}
                            />
                            <BrowserRoute
                              path="/company/feedback"
                              render={() => <Redirect to="/company/help" />}
                            />

                            {/* CANDIDATE PROFILE REDIRECTS AFTER ROUTE REFACTORING */}
                            <BrowserRoute
                              path="/company/discover/search/candidate/:id"
                              render={routeProps => (
                                <Redirect
                                  to={`/company/discover/search/u/candidate/${routeProps.match.params.id}`}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/company/discover/stream/:sid/candidate/:id"
                              render={routeProps => (
                                <Redirect
                                  to={`/company/discover/stream/${routeProps.match.params.sid}/u/candidate/${routeProps.match.params.id}`}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/company/discover/saved/candidates/candidate/:id"
                              render={routeProps => (
                                <Redirect
                                  to={`/company/discover/saved/candidates/u/candidate/${routeProps.match.params.id}`}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/company/messages/:lid/:cid/candidate/:id"
                              render={routeProps => (
                                <Redirect
                                  to={`/company/messages/${routeProps.match.params.lid}/${routeProps.match.params.cid}/u/candidate/${routeProps.match.params.id}`}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/candidate/dashboard"
                              render={() => <Redirect to="/candidate/insights" />}
                            />
                            {/* END OF REDIRECTS */}

                            <BrowserRoute
                              path="/demo/create-account"
                              exact
                              render={routeProps => (
                                <DemoCreateAccountProfile
                                  {...routeProps}
                                  {...transitionState}
                                  onLoginStatusChange={() => transitionState.onLoginStatusChange!()}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/search"
                              render={routeProps => (
                                <Search
                                  darkMode={darkMode}
                                  {...routeProps}
                                  {...transitionState}
                                  profile={profile}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/forgot-password"
                              exact
                              render={routeProps => <ForgotPassword {...routeProps} />}
                            />
                            <BrowserRoute
                              path="/forgotpassword/reset"
                              exact
                              render={routeProps => <PasswordReset {...routeProps} />}
                              darkMode={darkMode}
                            />
                            <BrowserRoute
                              path={['/pricing', '/uk/pricing', '/us/pricing']}
                              exact
                              render={routeProps => (
                                <Pricing
                                  {...routeProps}
                                  profile={profile}
                                  demoCompany={demoCompany}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/pricing-portal-pip-pip"
                              exact
                              render={routeProps => (
                                <Pricing
                                  {...routeProps}
                                  profile={profile}
                                  demoCompany={demoCompany}
                                  pricingPortal
                                />
                              )}
                            />
                            <BrowserRoute
                              path={['/checkout/:path', '/uk/checkout/:path', '/us/checkout/:path']}
                              exact
                              render={routeProps => (
                                <Checkout {...routeProps} profile={companyProfile} />
                              )}
                            />
                            <BrowserRoute
                              path="/testimonials"
                              render={routeProps => (
                                <Testimonials {...transitionState} {...routeProps} />
                              )}
                            />
                            <BrowserRoute
                              path="/stories"
                              exact
                              render={routeProps => (
                                <CustomerStories {...transitionState} {...routeProps} />
                              )}
                            />
                            <BrowserRoute
                              path="/stories/:id-:name"
                              render={routeProps => (
                                <CustomerStory {...transitionState} {...routeProps} />
                              )}
                            />
                            <BrowserRoute
                              path="/partners"
                              exact
                              component={Partners}
                              darkMode={darkMode}
                            />
                            <BrowserRoute
                              path="/partners/:id-:name"
                              render={routeProps => <Partnership {...routeProps} />}
                            />
                            <BrowserRoute
                              path="/partnerships/salary-tool"
                              exact
                              render={routeProps => (
                                <SalaryToolPartnershipLandingPage
                                  {...routeProps}
                                  {...transitionState}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/survey"
                              render={routeProps => (
                                <PMFSurvey
                                  {...routeProps}
                                  profile={transitionState.profile}
                                  surveyType="candidate"
                                />
                              )}
                              darkMode={darkMode}
                            />
                            <BrowserRoute
                              path="/survey-company"
                              render={routeProps => (
                                <PMFSurvey
                                  {...routeProps}
                                  profile={transitionState.profile}
                                  surveyType="company_account"
                                />
                              )}
                              darkMode={darkMode}
                            />
                            <BrowserRoute
                              path="/sitemap"
                              exact
                              render={routeProps => <Sitemap {...routeProps} />}
                            />
                            <BrowserRoute
                              path={[
                                '/companies',
                                '/uk/companies',
                                '/us/companies',
                                '/companies/invite',
                                '/uk/companies/invite',
                                '/us/companies/invite'
                              ]}
                              render={routeProps => (
                                <ForCompanies
                                  profile={transitionState.profile}
                                  demoCompany={transitionState.demoCompany}
                                  history={routeProps.history}
                                  location={routeProps.location}
                                />
                              )}
                            />
                            <BrowserRoute path="/agencies" render={() => <ForAgencies />} />
                            <BrowserRoute
                              path="/slack"
                              render={routeProps => <Slack {...transitionState} {...routeProps} />}
                            />
                            <BrowserRoute
                              path="/contact"
                              exact
                              render={routeProps => <Contacts {...routeProps} />}
                            />
                            <BrowserRoute
                              path="/about"
                              exact
                              render={routeProps => <About {...routeProps} darkMode={darkMode} />}
                            />
                            <BrowserRoute
                              path="/brand"
                              exact
                              render={routeProps => <Brand {...routeProps} darkMode={darkMode} />}
                            />
                            <BrowserRoute path="/team" exact render={() => <Team />} />
                            <BrowserRoute
                              path="/careers"
                              exact
                              render={routeProps => <Careers {...routeProps} />}
                            />
                            <BrowserRoute
                              path="/unsubscribe"
                              exact
                              render={routeProps => <Unsubscribe {...routeProps} />}
                            />
                            <BrowserRoute
                              path="/company/invite/:code"
                              render={routeProps => (
                                <CompanyInvitePage
                                  {...routeProps}
                                  {...transitionState}
                                  onLoginStatusChange={() => transitionState.onLoginStatusChange!()}
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/techhub/:topic/:type/:uid"
                              exact
                              render={routeProps => <InsightsContent {...routeProps} />}
                            />
                            <BrowserRoute
                              path="/techhub/:topic"
                              exact
                              render={routeProps => (
                                <InsightsTopicsPage {...routeProps} {...transitionState} />
                              )}
                            />
                            <BrowserRoute
                              path="/techhub"
                              exact
                              render={routeProps => (
                                <InsightsHomepage {...routeProps} {...transitionState} />
                              )}
                            />

                            {/* OLD INSIGHTS REDIRECT TO TECHHUB */}
                            <Redirect from="/insights" to="/techhub" exact />
                            <BrowserRoute
                              path="/insights/:path*"
                              render={({ match }) => (
                                <Redirect to={`/techhub/${match.params.path}`} />
                              )}
                            />

                            <BrowserRoute
                              path="/hiring-insights/:topic/:type"
                              exact
                              render={routeProps => (
                                <InsightsTypePage {...routeProps} {...transitionState} />
                              )}
                            />
                            <BrowserRoute
                              path="/hiring-insights/:topic/:type/:uid"
                              exact
                              render={routeProps => (
                                <InsightsContent {...routeProps} {...transitionState} />
                              )}
                            />
                            <BrowserRoute
                              path="/hiring-insights/:topic"
                              exact
                              render={routeProps => (
                                <InsightsTopicsPage {...routeProps} {...transitionState} />
                              )}
                            />
                            <BrowserRoute
                              path="/hiring-insights"
                              exact
                              render={routeProps => (
                                <InsightsHomepage {...routeProps} {...transitionState} />
                              )}
                            />
                            <BrowserRoute
                              path="/conversations"
                              exact
                              render={routeProps => (
                                <ConversationCode {...routeProps} {...transitionState} />
                              )}
                            />
                            <BrowserRoute
                              path="/salary-tool"
                              exact
                              render={routeProps => (
                                <SalaryToolLandingPage {...routeProps} {...transitionState} />
                              )}
                            />
                            <BrowserRoute
                              path={['/request-demo', '/trial', '/book-a-call']}
                              render={routeProps => (
                                <RequestDemo
                                  {...transitionState}
                                  {...routeProps}
                                  profile={
                                    isCompanyProfile(transitionState.profile)
                                      ? transitionState.profile
                                      : null
                                  }
                                />
                              )}
                            />
                            <BrowserRoute
                              path="/hires"
                              render={({ location }) => <HiresPage location={location} />}
                            />
                            <BrowserRoute
                              path={['/founder-program', '/es-su-programme', '/san-francisco']}
                              render={() => <FounderProgram />}
                            />
                            <BrowserRoute
                              path="/awards/:season/:award/:size"
                              render={() => <AwardsPage />}
                            />
                            <PublicRoute path="/" exact component={Homepage} {...transitionState} />
                            <PublicRoute
                              path="/signup"
                              exact
                              component={Homepage}
                              {...transitionState}
                            />
                            <PublicRoute
                              path="/sign-up"
                              exact
                              component={Homepage}
                              {...transitionState}
                            />
                            <PublicRoute
                              path="/salary-tool-signup"
                              exact
                              component={Loader}
                              className="large page_loader"
                              {...transitionState}
                            />
                            <PublicRoute
                              path={['/u', '/categories']}
                              component={Homepage}
                              {...transitionState}
                            />
                            <PublicRoute
                              path="/login"
                              exact
                              component={Login}
                              {...transitionState}
                            />
                            <PublicRoute
                              path="/admin-login"
                              exact
                              component={AdminLogin}
                              {...transitionState}
                            />
                            <PublicRoute
                              path="/jointeam"
                              exact
                              component={JoinTeam}
                              {...transitionState}
                            />
                            <PublicRoute
                              path="/create-password"
                              exact
                              component={CreatePassword}
                              {...transitionState}
                            />
                            <PublicRoute
                              path={['/invited', '/get-access', '/people']}
                              component={Invite}
                              {...transitionState}
                            />

                            <CompanyRoute
                              path="/company/profile"
                              exact
                              title="Company Profile"
                              shareURLType={SHARE_URL_TYPE.COMPANY}
                              component={CompanyProfileView}
                              {...transitionState}
                              updateMemberProfile={() => this.updateMemberProfile()}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/profile/edit"
                              exact
                              title="Edit Company Profile"
                              className="company_edit_profile"
                              component={CompanyEditProfile}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/member"
                              exact
                              title="My Profile"
                              shareURLType={SHARE_URL_TYPE.USER}
                              component={CompanyUserProfile}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/member/edit"
                              title="Edit Profile"
                              exact
                              component={CompanyEditUserProfile}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/account"
                              title="Settings"
                              component={CompanySettings}
                              getCompanyData={() => this.getCompanyData()}
                              onMemberPositionsUpdate={(id: number) =>
                                this.onMemberPositionsUpdate(id)
                              }
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/templates"
                              component={CompanyTemplates as ComponentType<any>}
                              onCompanyOnboardingItemCompletion={(f: CompanyOnboardingItem) =>
                                this.onCompanyOnboardingItemCompletion(f)
                              }
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/help"
                              title="Get Help"
                              component={CompanyHelp}
                              onCompanyOnboardingItemCompletion={(f: CompanyOnboardingItem) =>
                                this.onCompanyOnboardingItemCompletion(f)
                              }
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/referrals"
                              exact
                              title="Referrals"
                              component={Referrals}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/team"
                              exact
                              title="Team"
                              component={CompanyUsers}
                              {...transitionState}
                              updateHiringSeats={(action: 'add' | 'remove') =>
                                this.updateHiringSeats(action)
                              }
                              updatePermissions={(permissions: Permissions) =>
                                this.updatePermissions(permissions)
                              }
                              getCompanyData={() => this.getCompanyData()}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/analytics"
                              pageStages={
                                permissions.teamAnalytics || permissions.positionAnalytics
                                  ? [
                                      { url: '/company/analytics', name: 'Company', exact: true },
                                      ...(permissions.teamAnalytics
                                        ? [{ url: '/company/analytics/team', name: 'Users' }]
                                        : []),
                                      ...(permissions.positionAnalytics
                                        ? [
                                            {
                                              url: '/company/analytics/positions',
                                              name: 'Positions'
                                            }
                                          ]
                                        : [])
                                    ]
                                  : undefined
                              }
                              title="Analytics"
                              component={CompanyAnalytics}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/salary-tool"
                              exact
                              title="Salary tool"
                              component={SalaryTool}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path={['/company/events/edit/:id', '/company/events/create']}
                              title="Edit Interview Event"
                              component={CompanyCalendarEventEdit}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/events"
                              title="Interview Events"
                              component={CompanyCalendarEvents}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path={[
                                '/company/discover/saved/candidates',
                                '/company/discover/shortlists/:id'
                              ]}
                              title="Discover"
                              isMobileTitle
                              component={CompanyLists}
                              {...transitionState}
                              onCompanyOnboardingItemCompletion={(f: CompanyOnboardingItem) =>
                                this.onCompanyOnboardingItemCompletion(f)
                              }
                              reloadCompanyShortlists={() => this.reloadCompanyShortlists()}
                              getCompanyData={() => this.getCompanyData()}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/discover/shortlists"
                              title="Discover"
                              isMobileTitle
                              component={CompanyShortlists}
                              {...transitionState}
                              onCompanyOnboardingItemCompletion={(f: CompanyOnboardingItem) =>
                                this.onCompanyOnboardingItemCompletion(f)
                              }
                              reloadCompanyShortlists={() => this.reloadCompanyShortlists()}
                              getCompanyData={() => this.getCompanyData()}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/discover/search"
                              title="Discover"
                              isMobileTitle
                              component={CompanySearch}
                              {...transitionState}
                              reloadCompanyShortlists={() => this.reloadCompanyShortlists()}
                              onCompanyOnboardingItemCompletion={(f: CompanyOnboardingItem) =>
                                this.onCompanyOnboardingItemCompletion(f)
                              }
                              reloadCompanyStreams={() => this.reloadCompanyStreams()}
                              getCompanyData={() => this.getCompanyData()}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/positions"
                              title="Positions"
                              component={CompanyPositions}
                              {...transitionState}
                            />
                            <CompanyRoute
                              path={[
                                '/company/discover/stream/:id',
                                '/company/discover/stream/new',
                                '/company/discover'
                              ]}
                              title="Discover"
                              isMobileTitle
                              component={CompanyStreams}
                              {...transitionState}
                              onCompanyOnboardingItemCompletion={(f: CompanyOnboardingItem) =>
                                this.onCompanyOnboardingItemCompletion(f)
                              }
                              getCompanyData={() => this.getCompanyData()}
                              reloadCompanyShortlists={() => this.reloadCompanyShortlists()}
                              reloadCompanyStreams={() => this.reloadCompanyStreams()}
                              history={history}
                            />

                            {isFeatureEnabled({
                              featureName: 'new_inbox',
                              // @ts-expect-error: TODO: fix this
                              preferences: profile?.companyUser?.preferences
                            }) && !isTabletLandscapeWidth ? (
                              <CompanyRoute
                                path="/company/messages"
                                title="Messages"
                                isMobileTitle
                                component={CompanyMessagesV2}
                                onCompanyOnboardingItemCompletion={(f: CompanyOnboardingItem) =>
                                  this.onCompanyOnboardingItemCompletion(f)
                                }
                                getCompanyData={() => this.getCompanyData()}
                                updateProfileState={(key, value) =>
                                  this.updateProfileState(key, value)
                                }
                                updateMemberProfile={() => this.updateMemberProfile()}
                                {...transitionState}
                                history={history}
                              />
                            ) : (
                              <CompanyRoute
                                path="/company/messages"
                                title="Messages"
                                isMobileTitle
                                component={CompanyMessages}
                                onCompanyOnboardingItemCompletion={(f: CompanyOnboardingItem) =>
                                  this.onCompanyOnboardingItemCompletion(f)
                                }
                                getCompanyData={() => this.getCompanyData()}
                                updateMessageFilter={(filter: MessageFilter) =>
                                  this.updateMessageFilter(filter)
                                }
                                {...transitionState}
                                history={history}
                              />
                            )}

                            <CompanyRoute
                              path="/company/position/:id/view"
                              exact
                              shareURLType={SHARE_URL_TYPE.POSITION}
                              component={CompanyPositionProfileView}
                              getCompanyData={() => this.getCompanyData()}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/position/:id/edit"
                              exact
                              component={CompanyPositionEdit}
                              {...transitionState}
                              onPositionPause={(positionID: number, resume: boolean) =>
                                this.onPositionPause(positionID, resume)
                              }
                              getCompanyData={() => this.getCompanyData()}
                              history={history}
                            />
                            <CompanyRoute
                              path={['/company/create-position', '/company/setup/positions/create']}
                              title="Create Position"
                              exact
                              component={CompanyCreatePosition}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path={[
                                '/company/live-leads/search/:id',
                                '/company/live-leads/search/new',
                                '/company/live-leads'
                              ]}
                              title="Live leads"
                              component={CompanyLiveLeads}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/setup/positions"
                              title="My Positions"
                              component={CompanySetupPositions}
                              onMemberPositionsUpdate={(id: number) =>
                                this.onMemberPositionsUpdate(id)
                              }
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/setup/complete-profile"
                              title="Profile"
                              component={CompanyEditUserProfile}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/setup/review-profile"
                              title="Profile"
                              component={CompanyUserProfile}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/setup/finish"
                              title="Finish Setup"
                              component={CompanySetupFinish}
                              reloadCompanyData={() => this.getCompanyData()}
                              onSetupFinish={() => {
                                this.props.setSetupMode(false);
                                this.props.setInitialSetupMode(false);
                              }}
                              {...transitionState}
                              history={history}
                            />
                            <CompanyRoute
                              path="/company/setup/account/:type"
                              component={CompanySetupAccount}
                              {...transitionState}
                              history={history}
                            />

                            <CandidateRoute
                              path="/candidate/discover"
                              component={CandidateDiscover}
                              hideWelcomeBackPrompt={() => this.hideWelcomeBackPrompt()}
                              getCandidateData={() => this.getCandidateData()}
                              {...transitionState}
                              history={history}
                            />
                            <CandidateRoute
                              path="/candidate/search"
                              component={CandidateSearch}
                              {...transitionState}
                              getCandidateData={() => this.getCandidateData()}
                              history={history}
                            />
                            <CandidateRoute
                              path="/candidate/messages"
                              component={CandidateMessages}
                              {...transitionState}
                              getCandidateData={() => this.getCandidateData()}
                              history={history}
                            />
                            <CandidateRoute
                              path="/candidate/account"
                              component={CandidateAccount}
                              getCandidateData={() => this.getCandidateData()}
                              {...transitionState}
                              history={history}
                            />
                            <CandidateRoute
                              path="/candidate/settings"
                              component={CandidateSettings}
                              {...transitionState}
                              getCandidateData={() => this.getCandidateData()}
                              history={history}
                            />
                            <CandidateRoute
                              path="/candidate/help"
                              component={CandidateHelp}
                              {...transitionState}
                              history={history}
                            />
                            <CandidateRoute
                              path="/candidate/templates"
                              exact
                              component={CandidateTemplates}
                              {...transitionState}
                              history={history}
                            />
                            <CandidateRoute
                              path="/candidate/invites"
                              exact
                              component={CandidateInvites}
                              {...transitionState}
                              history={history}
                            />
                            <CandidateRoute
                              path="/candidate/insights"
                              component={SalaryTool}
                              {...transitionState}
                            />
                            <CandidateRoute
                              path="/candidate/tracker"
                              component={ApplicationTracker}
                            />
                            <AdminRoute
                              path="/admin/tagging"
                              component={AdminTagging}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/dashboard"
                              component={AdminDashboard}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/tech/tools"
                              component={AdminTechTools}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/tech/sql-editor"
                              exact
                              component={AdminSQlEditor}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/tech/sql-editor/create"
                              component={AdminCreateSQLWriteQuery}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/product/tools"
                              component={AdminProductTools}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/cv"
                              component={CVBuilder}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/tools"
                              component={AdminCandidateTools}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/search"
                              component={AdminCandidateSearch}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/verify"
                              onCandidateVerified={count => this.onCandidateVerified(count)}
                              component={AdminCandidatesSignup}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/build"
                              onCandidateVerified={count => this.onCandidateVerified(count)}
                              initTab={CSVS.Accepted}
                              component={AdminCandidatesSignup}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/review"
                              onCandidateVerified={count => this.onCandidateVerified(count)}
                              initTab={CSVS.PendingSelfBuildReview}
                              component={AdminCandidatesSignup}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/waitlisted"
                              onCandidateVerified={count => this.onCandidateVerified(count)}
                              initTab={CSVS.Waitlisted}
                              component={AdminCandidatesSignup}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/rejected"
                              onCandidateVerified={count => this.onCandidateVerified(count)}
                              initTab={CSVS.Rejected}
                              component={AdminCandidatesSignup}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/reactivated"
                              onCandidateVerified={count => this.onCandidateVerified(count)}
                              initTab={CSVS.Reactivated}
                              component={AdminCandidatesSignup}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/candidate/re-build"
                              onCandidateVerified={count => this.onCandidateVerified(count)}
                              initTab={CSVS.PendingReactivationReview}
                              component={AdminCandidatesSignup}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/account"
                              exact
                              component={AdminAccountView}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/users"
                              exact
                              component={AdminUsers}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/dashboard"
                              component={AdminCSDashboard}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/verify"
                              onCompanyVerified={() => this.onCompanyVerified()}
                              component={AdminCompanyVerify}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/tools"
                              component={AdminCompanyTools}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/stories"
                              exact
                              component={AdminCompanyStories}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/stories/create"
                              component={AdminCreateCompanyStory}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/partnerships"
                              exact
                              component={AdminCompanyPartnerships}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/partnerships/create"
                              component={AdminCompanyCreatePartnership}
                              {...transitionState}
                            />
                            <AdminRoute
                              path={[
                                '/admin/company/checkout',
                                '/admin/company/checkout/live',
                                '/admin/company/checkout/test'
                              ]}
                              exact
                              component={AdminCompanyCheckout}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/checkout/create"
                              component={AdminCompanyCreateCheckout}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/search"
                              exact
                              component={AdminCompanySearch}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/credit-system"
                              exact
                              component={AdminCreditSystem}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/cs-tools"
                              component={AdminCSTools}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/wheel"
                              exact
                              component={AdminWheel}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/company/credit-refunds"
                              exact
                              component={AdminCreditRefund}
                              {...transitionState}
                            />
                            <AdminRoute
                              path="/admin/external-positions"
                              exact
                              component={AdminExternalPositions}
                              {...transitionState}
                            />

                            {/* INTEGRATIONS OAUTH REDIRECT*/}
                            <BrowserRoute
                              path="/integrations"
                              render={() => (
                                <Redirect to={integrationRedirectURL + window.location.search} />
                              )}
                            />

                            <BrowserRoute component={NotFound} />
                          </Switch>
                        </Suspense>
                      </ErrorBoundary>
                    </main>
                    <BrowserRoute render={routeProps => <Footer {...routeProps} />} />
                  </div>
                </div>
                <NotificationBar
                  title={<span className="icon_warning" />}
                  text="You're offline"
                  show={transitionState.showOfflineNotification}
                  color="red"
                />
              </div>
            </SkeletonTheme>
          </Router>
        </CordTheme>
      );
    }
  }
}

const mapStateToProps = (state: RootState) => ({
  admin: selectAdminAccount(state),
  candidateProfile: selectCandidateProfile(state),
  candidateTour: selectCandidateTour(state),
  candidatesToVerify: selectCandidatesToVerify(state) || 0,
  companiesToVerify: selectCompaniesToVerify(state) || 0,
  companyProfile: selectCompanyProfile(state),
  darkMode: selectDarkMode(state),
  demoCompany: !!selectDemoCompany(state),
  demoCompanyTour: selectDemoCompanyTour(state),
  initialSetupMode: selectInitialSetupMode(state),
  insightsTrialBanner: selectToggler('insights.trialExpiringBar')(state),
  integrationRedirectURL: selectIntegrationRedirectURL(state),
  isProfileUpdated: selectIsProfileUpdated(state),
  loading: selectLoader()(state),
  permissions: selectPermissions(state),
  profile: selectProfile(state),
  sessionEndedPopup: selectSessionEndedPopup(state),
  setupMode: selectSetupMode(state),
  showCandidateTour: selectShowCandidateTour(state),
  showOfflineNotification: selectShowOfflineNotification(state),
  showReactivationModal: selectShowReactivationModal(state),
  showStartUsingCordPopup: selectShowStartUsingCordPopup(state),
  userType: selectUserType(state) || '',
  navigatablePositionIDs: selectNavigatablePositionsIDs(state),
  shouldShowNavigatablePositions: selectShouldShowNavigatablePositions(state)
});

const mapDispatchToProps = (dispatch: AppDispatch) =>
  bindActionCreators(
    {
      decrementCompaniesToVerify,
      initTheme,
      initLocalisation,
      decrementHiringSeats,
      fetchAdminData,
      fetchCandidateData,
      setAdminAccount,
      fetchCompanyData,
      incrementHiringSeats,
      persistCandidateTourBubbleShown,
      persistCompanyOnboardingItemCompleted,
      reloadCompanyNotifications,
      reloadCompanyStreams,
      reloadCompanyShortlists,
      removeCandidateTourBubble,
      setCandidatesToVerify,
      setCompanyStreams,
      setCompanyResponseRate: (responseRate: string) => setCompanyResponseRate({ responseRate }),
      setCompanyResponseTime: (responseTime: any) => setCompanyResponseTime({ responseTime }),
      setUserResponseRate: (responseRate: any) => setUserResponseRate({ responseRate }),
      setUserResponseTime: (responseTime: any) => setUserResponseTime({ responseTime }),
      setDemoCompanyTour,
      onDemoTourBubbleShown,
      setMessageFilter,
      setPermissions,
      setPositionSearchVisibility: (positionID: number, hidden: boolean) =>
        setPositionSearchVisibility({ positionID, hidden }),
      setPositionStatus: (positionID: number, resume: boolean) =>
        setPositionStatus({ positionID, resume }),
      setPreferences,
      setProfile,
      setReceivedRequestsPending,
      setIsProfileUpdated,
      setShouldPromptStatus,
      setCandidateNotifications: (notifications: CandidateNotificationResult) =>
        setCandidateNotifications(notifications),
      setTotalNotifications,
      setInitialSetupMode,
      setSetupMode,
      toggleReactivationModal,
      toggleShowOfflineNotification,
      toggleStartUsingCordPopup,
      toggleSessionEndedPopup,
      updateLoginStatus,
      updateMemberPosition,
      updateMemberProfile
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(App);
