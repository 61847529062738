import React, { MouseEventHandler } from 'react';
import { NavLink as Link } from 'react-router-dom';

import { useCandidateProfile, useToggler } from 'store';
import { logout } from 'fetcher';
import useMedia from 'hooks/useMedia';
import { mediaQueries } from 'responsiveConfig';

import StatusToggle from 'components/statustoggle';

import ConditionalRender from 'v2/components/utility/ConditionalRender';
import CandidateTourBubble from 'v2/components/CandidateTourBubble';
import ClickDetector from 'v2/components/utility/ClickDetector';

import { MenuItem, getTopMenuItems } from './AccountDropdown.helpers';
import { CandidateDropdown, CloseIcon, Menu, MenuCont } from './AccountDropdown.styled';

function AccountDropdownItem({ path, label, icon, bubbleName }: MenuItem) {
  const { toggle } = useToggler('candidate.accountMenuDropdown');

  const handleClick: MouseEventHandler = e => {
    if ((e.target as Element).closest('.tour_bubble_wrapper')) e.preventDefault();
    else setTimeout(toggle, 100);
  };

  return (
    <Link
      key={path}
      className="item"
      to={path === 'techhub' ? '/techhub' : `/candidate/${path}`}
      onClick={handleClick}
    >
      <span className={icon} />
      {label}
      <ConditionalRender predicate={bubbleName}>
        <CandidateTourBubble
          bubbleName={bubbleName!}
          centeredOnMobile
          showIfPrevBubbleShown={bubbleName !== 'profile'}
        />
      </ConditionalRender>
    </Link>
  );
}

export default function AccountDropdown() {
  const profile = useCandidateProfile()!;
  const isTablet = useMedia(mediaQueries.tablet);
  const { toggle } = useToggler('candidate.accountMenuDropdown');

  const hideDropdownOnOutsideClick = (e: MouseEvent) => {
    if (!(e.target as Element).closest('.account_dropdown_selector')) toggle();
  };

  const signOut: MouseEventHandler = async e => {
    e.preventDefault();
    await logout();
  };

  const items = getTopMenuItems(profile);

  return (
    <ClickDetector onClickOutside={hideDropdownOnOutsideClick}>
      <CandidateDropdown className="candidate_dropdown">
        <ConditionalRender predicate={isTablet}>
          <CloseIcon className="icon_close" onClick={() => toggle()} />
        </ConditionalRender>
        <StatusToggle />
        <MenuCont className="candidate_dropdown_list">
          <Menu className="candidate_dropdown_grouped_items">{items.map(AccountDropdownItem)}</Menu>
          <Menu className="candidate_dropdown_grouped_items">
            <ConditionalRender predicate={profile.isTechCandidate}>
              <AccountDropdownItem path="techhub" label="Tech hub" icon="icon_knowledge" />
            </ConditionalRender>
            <AccountDropdownItem
              path="settings"
              label="Settings"
              icon="icon_settings"
              bubbleName="settings"
            />
            <a className="item" href="/" onClick={signOut}>
              <span className="icon_exit" />
              Sign out
            </a>
          </Menu>
        </MenuCont>
      </CandidateDropdown>
    </ClickDetector>
  );
}
