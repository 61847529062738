import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

import {
  persistCandidateTourBubbleShown,
  selectCandidateTour,
  selectShowCandidateTour,
  toggleShowCandidateTour,
  useAppDispatch,
  useAppSelector
} from 'store';
import { CandidateBubbleName, useToggler } from 'store/ui';
import { getNextTourBubblePage } from 'utils';

import TourBubble, { TourBubbleProps } from 'components/tourbubble';

import { CANDIDATE_TOUR_BUBBLE_CONTENT } from './CandidateTourBubble.helpers';

type CandidateTourBubbleProps = {
  bubbleName: CandidateBubbleName;
  showIfPrevBubbleShown?: boolean;
} & Pick<TourBubbleProps, 'align' | 'fixedOnMobile' | 'centeredOnMobile'>;

function CandidateTourBubble({
  bubbleName,
  align = 'center',
  fixedOnMobile,
  centeredOnMobile,
  showIfPrevBubbleShown
}: CandidateTourBubbleProps) {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const candidateTour = useAppSelector(selectCandidateTour) || [];

  const { toggle: toggleAccountDropdown } = useToggler('candidate.accountMenuDropdown');

  const shouldShowTourBubble = useCallback(
    (bubbleName: CandidateBubbleName) => {
      const index = candidateTour.findIndex(({ name }) => name === bubbleName);
      const isTourBubbleShown = candidateTour[index].shown;

      return !isTourBubbleShown && (!showIfPrevBubbleShown || candidateTour[index - 1]?.shown);
    },
    [candidateTour]
  );

  const { show, tourBubblesPending, isLastTourBubble } = useMemo(() => {
    const tourBubblesPending = candidateTour.filter(({ shown }) => !shown) || [];

    return {
      show: shouldShowTourBubble(bubbleName),
      tourBubblesPending,
      isLastTourBubble: tourBubblesPending.length <= 1
    };
  }, [candidateTour]);

  const closeBubble = (goNext?: boolean) => {
    dispatch(persistCandidateTourBubbleShown({ bubbleName }));

    if (!goNext) dispatch(toggleShowCandidateTour());
  };

  const onFinishTour = () => {
    closeBubble();
    history.push('/candidate/discover');

    if (toggleAccountDropdown) setTimeout(() => toggleAccountDropdown(false), 500);
  };

  const onNextBubble = () => {
    const nextPage = getNextTourBubblePage(tourBubblesPending, bubbleName);

    closeBubble(true);

    if (nextPage) {
      history.push(nextPage);

      if (new RegExp('/account').test(nextPage)) toggleAccountDropdown?.(true);
    }
  };

  const { title, body } = CANDIDATE_TOUR_BUBBLE_CONTENT[bubbleName];

  return (
    <TourBubble
      show={show}
      fixedOnMobile={fixedOnMobile}
      centeredOnMobile={centeredOnMobile}
      title={title}
      body={body}
      position="bottom"
      align={align}
      buttonText={isLastTourBubble ? 'Finish' : 'Next'}
      onButtonClick={isLastTourBubble ? onFinishTour : onNextBubble}
      onClose={closeBubble}
    />
  );
}

export default function CandidateBubbleRender(props: CandidateTourBubbleProps) {
  const showCandidateTour = useAppSelector(selectShowCandidateTour);

  if (showCandidateTour) return <CandidateTourBubble {...props} />;
  return null;
}
