import { CompanySubscriptionPlan, OperatingCountry } from '@cohiretech/common-types';

import {
  CurrentPlanDetails,
  PayPerHirePricingOptions,
  PricingCountry,
  PricingVer,
  SubscriptionPlan
} from 'types';
import { ES_SU_PROGRAM_BLOCK } from 'consts';
import { getIsLocalisedPricingPage } from 'utils/companyUtils';
import { getLocalStorageItem } from 'cookieManager';

import { BlockProps } from 'components/block';
import { TabType } from 'components/tabs';

export const getPricingType = (pathname?: string, pricingVer?: PricingVer) => {
  if (pricingVer === 5) return OperatingCountry.UK;

  const isLocalisedPricingPage = getIsLocalisedPricingPage(pathname);
  const storedUKorUSPricing = getStoredUKorUSPricing();

  return (
    isLocalisedPricingPage ? isLocalisedPricingPage.toUpperCase() : storedUKorUSPricing
  ) as PricingCountry;
};

export const getStoredUKorUSPricing = () => {
  const storedLocalisation =
    getLocalStorageItem<OperatingCountry | ''>('country') || OperatingCountry.UK;
  if (storedLocalisation === OperatingCountry.EU) return OperatingCountry.UK;

  return storedLocalisation;
};

export const DISCOUNT_BLOCK_PROPS: { [ver in PricingVer]: BlockProps } = { 5: ES_SU_PROGRAM_BLOCK };

export const PAY_PER_HIRE_TABS: TabType[] = [
  {
    title: 'Unlimited hires',
    value: 'unlimited'
  },
  {
    title: 'Pay per hire',
    value: 'payperhire'
  }
];

export type PricingTabs = 'unlimited' | 'payperhire';

export const getShowPayPerHire = (
  currentPlanDetails?: CurrentPlanDetails<SubscriptionPlan>,
  pricingType?: PricingCountry
) => {
  const plan = currentPlanDetails?.plan;

  if (pricingType === OperatingCountry.US) return false;

  return (
    !plan || plan === PayPerHirePricingOptions.PayPerHire || plan === CompanySubscriptionPlan.Basic
  );
};
