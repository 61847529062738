import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { useCandidateProfile } from 'store';
import { noop } from 'utils/fn';

import Badge from 'components/badge';

import SimpleSearchBar from 'v2/components/SimpleSearchBar';
import MenuIcon from 'v2/components/ui/atoms/MenuIcon';
import CandidateTourBubble from 'v2/components/CandidateTourBubble';

import '../style.scss';

import { getCandidateNavLinks } from './helpers';
import AccountMenuDropdown from './AccountMenuDropdown';

export default function CandidateMenu() {
  const { pathname } = useLocation();
  const { totalNotifications, accepted } = useCandidateProfile() || {};

  return (
    <MenuCont id="candidate_menu">
      <SimpleSearchBar />
      <RightMenu className="candidate_right_menu">
        {getCandidateNavLinks({ accepted }).map(
          ({ key, icon, bubbleName, badge, badgeType = 'new_feature', title, onClick = noop }) => {
            const isTechHub = key === 'techhub';
            const linkTo = isTechHub ? '/techhub' : `/candidate/${key}`;

            return (
              <CandidateMenuIcon
                key={key}
                icon={`icon_${icon}`}
                tooltip={{
                  text: [
                    title,
                    badge && <Badge key={`${key}_badge`} type={badgeType} text={badge} />
                  ],
                  position: 'bottom'
                }}
                count={key === 'messages' ? totalNotifications : undefined}
                to={linkTo}
                onClick={onClick}
                className="candidate_menu_item"
                hideOnMobile={isTechHub}
              >
                {bubbleName && new RegExp(`/candidate/${key}`).test(pathname) && (
                  <CandidateTourBubble bubbleName={bubbleName} align="center" fixedOnMobile />
                )}
              </CandidateMenuIcon>
            );
          }
        )}
        <AccountMenuDropdown />
      </RightMenu>
    </MenuCont>
  );
}

const MOBILE_RIGHT_MENU_GAP = '0.5rem;';

const MenuCont = styled.div`
  display: flex;
  align-items: center;

  ${({ theme: { media } }) => media.mobileMin`
    justify-content: space-between;
  `}
`;

const RightMenu = styled.div`
  display: flex;
  gap: 0.75rem;
  align-items: center;

  ${({ theme: { media } }) => media.mobile`
    gap: ${MOBILE_RIGHT_MENU_GAP}
    margin-left: ${MOBILE_RIGHT_MENU_GAP} // For search icon button
  `}
`;

const CandidateMenuIcon = styled(MenuIcon)<{ hideOnMobile: boolean }>`
  ${({ hideOnMobile, theme: { media } }) =>
    hideOnMobile &&
    media.tinyMobile`
    display: none;
  `}
`;
