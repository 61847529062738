import { ParsedCandidateLocationPreference as PCLP, RemoteOption } from '@cohiretech/common-types';

import { getRemoteDays, getRemoteOptions } from 'fetcher';
import { getRemoteLocations, isEmpty, pluralise, setAcronym } from 'utils';
import { compact, difference, take } from 'utils/array';
import { isString } from 'utils/fn';

const { Hybrid, Remote } = RemoteOption;

enum OnsiteHybridOption {
  OnsiteHybrid = 'Onsite/Hybrid'
}

export type ProcessedPreference = Omit<PCLP, 'locations'> & { locations: string[] };

export const getRemoteDaysWithDescriptiveLabel = () =>
  getRemoteDays().map(({ value }) => ({
    label: pluralise(value, 'day remote', 'days remote'),
    value
  }));

const getRemoteLabel = (type: RemoteOption, isOnsiteMerged?: boolean) => {
  if (type === Hybrid && isOnsiteMerged) return OnsiteHybridOption.OnsiteHybrid;
  return getRemoteOptions('engineer').find(({ value }) => value === type)?.label || '';
};

const getAllRemoteLocations = ({ remoteLocationCountries, remoteLocationContinents }: PCLP) =>
  (remoteLocationCountries || []).concat(remoteLocationContinents || []);

/** Use setAcronym to shorten locations and remove any null locations. */
export const processLocations = (locationPreferences: PCLP[] = []): ProcessedPreference[] =>
  locationPreferences.map(pref => {
    const { type } = pref;
    const locations = (type === Remote ? getAllRemoteLocations(pref) : pref.locations) || [];

    const shortenedLocations = locations.map(loc => {
      const label = isString(loc) ? loc : loc.label;
      return label ? setAcronym(label) : null;
    });

    return { ...pref, locations: compact<string>(shortenedLocations) };
  });

const convertLocationsToString = (type: RemoteOption, locations: string[]) => {
  if (type === Remote) return getRemoteLocations(locations, []);
  return !isEmpty(locations) ? ` in ${locations.join(' / ')}` : '';
};

export const getLocationMainInfo = ({
  type,
  locations,
  isOnsiteMerged,
  limit = 0
}: ProcessedPreference & {
  isOnsiteMerged?: boolean;
  limit?: number;
}) => {
  const remoteLabel = getRemoteLabel(type, isOnsiteMerged);
  const locs = limit ? take(2)(locations) : locations;
  const formattedLocations = convertLocationsToString(type, locs);
  const remainderItemsCount = limit && locations.length - limit;

  return `${remoteLabel} ${formattedLocations} ${
    remainderItemsCount > 0 ? ` +${remainderItemsCount} more` : ''
  }`;
};

/** Check if there's only one item each for Onsite and Hybrid, with identical locations. */
export const checkMatchingOnsiteHybridLocations = (locationPreferences: ProcessedPreference[]) => {
  const locs: { [key in RemoteOption]?: string[] } = {};

  for (const { type, locations } of locationPreferences) {
    if (type !== Remote) {
      if (locs[type]) return false;
      locs[type] = locations;
    }
  }

  const { onsite, hybrid } = locs;
  return onsite && hybrid && isEmpty(difference(onsite, hybrid));
};
