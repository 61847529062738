import { ReactNode } from 'react';

import {
  OperatingCountry,
  CompanySubscriptionPlan,
  Frequency,
  SubscriptionInterface,
  SubscriptionPlanInterface,
  PricingSubscriptionPlan,
  PricingMode
} from '@cohiretech/common-types';

import { STANDARD_PLAN_LIST } from 'consts';

import { Props as ButtonProps } from 'components/button';

export enum NewPricingOptions {
  Starter = 'starterV1',
  Growth = 'growth_L1_V1',
  GrowthPlus = 'growth_L2_V1',
  Scaling = 'scalingV1',
  Unlimited = 'unlimitedV1',
  StarterV2 = 'starterV2',
  GrowthV2 = 'growth_L1_V2',
  GrowthPlusV2 = 'growth_L2_V2',
  ScalingV2 = 'scalingV2',
  UnlimitedV2 = 'unlimitedV2',
  StarterV2Annual = 'V2_starter_annual',
  GrowthV2Annual = 'V2_growth_25_annual',
  GrowthPlusV2Annual = 'V2_growth_35_annual',
  ScalingV2Annual = 'V2_scaling_annual'
}

export enum V3PricingOptions {
  V3Growth20 = 'V3_growth_20',
  V3Growth30 = 'V3_growth_30',
  V3Growth40 = 'V3_growth_40',
  V3Growth50 = 'V3_growth_50',
  V3Unlimited = 'V3_unlimited'
}

export enum V4PricingOptions {
  V4Growth = 'V4_growth',
  V4Unlimited = 'V4_unlimited'
}

export enum V5PricingOptions {
  V5Standard = 'V5_standard',
  // The options below don't exist in the DB.
  V5Standard1 = 'V5_standard_1',
  V5Standard2 = 'V5_standard_2',
  V5Standard3 = 'V5_standard_3',
  V5Standard4 = 'V5_standard_4',
  V5Standard5 = 'V5_standard_5'
}

export enum PayPerHirePricingOptions {
  PayPerHire = 'pay_per_hire'
}

export type V1ExternalPricingOptions = 'V1_external';

export enum AgencySubscriptionPlans {
  V1 = 'V1_agency'
}

export type PricingCountry = OperatingCountry.UK | OperatingCountry.US;
export type PricingPlanType = Exclude<
  NewPricingOptions,
  NewPricingOptions.GrowthPlus | NewPricingOptions.GrowthPlusV2
>;
export type PricingData = {
  conversations: {
    label: string | (string | ReactNode)[];
    value: string | number;
    price: string;
    hasDiscount: boolean;
    discountedPrice: string;
    plan: NewPricingOptions;
    button: ButtonProps;
    link: { text: string; to?: string; onClick?: () => void };
  }[];
};

export type PricingOptionType = {
  label: string;
  description: string;
  value: NewPricingOptions;
  conversations: number;
  positions: number;
  users: number;
  features: {
    name: string;
    tooltip: { text: string; wider?: boolean };
    popup?: { subtitle: string; image: string };
  }[];
  extraFeature?: string;
} & Record<PricingCountry, { [key in Frequency]: PricingData }>;

export type PricingOptionsType = {
  [key in PricingPlanType]: PricingOptionType;
};

export type SubscriptionPlan =
  | V5PricingOptions
  | V4PricingOptions
  | V3PricingOptions
  | NewPricingOptions
  | CompanySubscriptionPlan
  | V1ExternalPricingOptions
  | AgencySubscriptionPlans
  | PayPerHirePricingOptions;

export type UpgradeAction = 'upgrade' | 'downgrade';
export type PricingBtnAction = UpgradeAction | 'annual' | 'reactivation';

export type SubscriptionPricing = {
  previousSubscription: SubscriptionInterface;
  previousSubscriptionPlan: SubscriptionPlanInterface & { plan?: SubscriptionPlan };
  availablePlans: PricingSubscriptionPlan[];
  pricingCountry: PricingCountry;
  urlPath: string;
};

export type PricingPlanDetails<T> = {
  label: string;
  description: string;
} & (T extends NewPricingOptions
  ? { conversations: number; positions: number; users: number; extraFeature?: string }
  : {});

export type CurrentPlanDetails<T = SubscriptionPlan> = {
  plan: T;
  billingFrequency: Frequency;
  urlPath?: string;
  mode?: PricingMode;
  hasDiscount?: boolean;
  signupDate?: string;
  isUnpaidAccount?: boolean;
};

export type UsageStatistics = {
  positions: number;
  userSeats: number;
  unlimitedCreditsExpiryDate: Date | null;
  credits: {
    usage: number;
    limit: number;
  };
};

export type InactivePlan =
  | CompanySubscriptionPlan.Basic
  | CompanySubscriptionPlan.Expired
  | CompanySubscriptionPlan.Suspended;

export type BillingList = { date: string; receiptURL: string }[];

export type PricingVer = 5;

export type StandardPlan<V extends PricingVer = PricingVer> = typeof STANDARD_PLAN_LIST[V][number];

export type SelectedPricing<P extends SubscriptionPlan = SubscriptionPlan> = {
  plan: P;
  frequency: Frequency;
  pricingCountry: PricingCountry;
};

export type PriceList<V extends PricingVer = PricingVer> = {
  [country in PricingCountry]?: {
    [key in StandardPlan<V>]: { price: number; discountedPrice?: number; allowFree?: boolean };
  };
};
