import {
  UserType,
  CandidateCleanInterface,
  ApplicationInterface,
  ApplicationStatus,
  CreditRefundRequestStatus,
  ParsedApplicationMessage,
  ParsedInviteObject,
  TypeDetails,
  ApplicationMatchScoreInterface
} from '@cohiretech/common-types';

import { CreditRefundRequestReason } from 'types';

export enum CompanyMessageType {
  Decline = 'decline',
  Accept = 'accept',
  Offer = 'offer'
}

export type ApplicationDetails = {
  applicationStatus: ApplicationStatus;
  reportReasons?: string[];
  refundStatus: CreditRefundRequestStatus | null;
  requestReason: CreditRefundRequestReason | null;
  applicationMatchScore: ApplicationMatchScoreInterface;
} & Required<ApplicationInterface>;

export type MessageThread = {
  applicationDetails: ApplicationDetails;
  messageHistory: ExtendedMessage[];
  // properties on company side
  lastName: CandidateCleanInterface['lastname'];
  positionName: string;
  queuedMessage: $TSFixMe;
  // properties on candidate side
  companyName: string;
  position: string;
  peopleInThread: TypeDetails[];
  listingID: number;
} & CandidateCleanInterface;

export type ExtendedMessage = {
  seenBy: (TypeDetails & { type?: UserType })[];
  inviteDetails: (ParsedInviteObject & { timezone: $TSFixMe }) | null;
  error?: true;
} & Omit<ParsedApplicationMessage, 'seenBy' | 'inviteDetails'>;

export type DeclineReason = {
  reason: string;
  reasonID: number;
  description?: string;
  value?: string;
  addNew?: boolean;
  icon?: string;
};

export type SimplifiedDeclineReason = Pick<DeclineReason, 'reasonID' | 'reason' | 'description'>;
